import { Menu } from "@headlessui/react";
import IcDown from "img/ic_down_white.svg";
import IcDownLight from "img/ic_down_whiteLight.svg";
import "./Bridge.scss";
import "./BridgeTheme.scss";
import styled from "styled-components";
import LayerZeroIcon from "img/layer0.svg";
import LayerZeroIconLight from "img/layer0Light.svg";
import MigrateReader from "abis/EsMigrate.json";
import NaviOFTAdapter from "abis/NaviOFTAdapter.json";
import FeeGmxTracker from "abis/FeeGmxTracker.json";
import icFantom from "img/ic_ftm_24.svg";
import icOp from "img/ic_op_24.svg";
import icArb from "img/ic_arbitrum_24.svg";
import icBase from "img/ic_base_24.svg";
import icScan from "img/ic_scan.svg";
import icScanLight from "img/ic_scanLight.svg";
import icScanWhite from "img/ic_scan_white.svg";
import icScanWhiteLight from "img/ic_scan_whiteLight.svg";
import icMMY from "img/bridge/mmy.svg";
import icNFT from "img/bridge/nft.svg";
import icPlus from "img/bridge/ic-plus.svg";
import icPlusLight from "img/bridge/ic-plusLight.svg";
import icCheckbox from "img/bridge/new_checkbox.svg";
import RewardTracker from "abis/RewardTracker.json";
import icCheckboxLight from "img/bridge/new_checkboxLight.svg";
import icCheckboxChecked from "img/bridge/new_checkbox_check.svg";
import icCheckboxCheckedLight from "img/bridge/new_checkbox_checkLight.svg";
import icNFTItem from "img/bridge/nft-item.svg";
import icBolt from "img/bridge/bolt.svg";
import icBoltLight from "img/bridge/boltLight.svg";
import icEmpty from "img/bridge/empty.png";
import Vester from "abis/Vester.json";
import { IoMdSwap } from "react-icons/io";
import { BASE, FANTOM, FANTOM_TESTNET, getConstant, getLayerZeroExplorer, getLinkCDN, SONIC } from "config/chains";
import { OP } from "config/chains";
import { ARBITRUM, getExplorerUrl } from "config/chains";
import { useEffect, useState } from "react";
import Footer from "components/Footer/Footer";
import bg from "img/earn/bg.svg";
import Tooltip from "components/Tooltip/Tooltip";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";
import { useWeb3React } from "@web3-react/core";
import {
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
  importImage,
  PLACEHOLDER_ACCOUNT,
} from "lib/legacy";
import { getContract } from "config/contracts";
import { useChainId } from "lib/chains";
import BridgedReader from "abis/Bridge.json";
import BridgedNFTReader from "abis/BridgeNFTNavi.json";
import { expandDecimals, formatAmount, formatNumber } from "lib/numbers";
import { BigNumber, ethers } from "ethers";
import { numberWithCommas } from "lib/numbers";
import { getBalanceAndSupplyData } from "lib/legacy";
import ReaderV2 from "abis/ReaderV2.json";
import { useBridgeApi, useGmxPrice } from "domain/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Token from "abis/Token.json";
import { approveTokens } from "domain/tokens";
import { parseValue } from "graphql";
import { switchNetwork } from "lib/wallets";
import Pagination from "pages/Analytics/components/Pagination";
import Modal from "components/Modal/Modal";
import SelectedNftItem from "./SelectedNftItem";
import { Link } from "react-router-dom";
import useGetListStakedNFT from "hooks/useGetListStakedNFT";
import NFTClub from "abis/NFTClub.json";
import { useThemeContext } from "contexts/ThemeProvider";
import { ValidationRow } from "pages/BeginAccountTransfer/BeginAccountTransfer";
import RewardReader from "abis/RewardReader.json";
import GlpManager from "abis/GlpManager.json";
import Vault from "abis/Vault.json";
import useMMYFarmContractMulticall from "hooks/contracts/useMMYFarmContractMulticall";
import useVMMYContract from "hooks/contracts/usevMMYContract";
import useUserMigrationNavi from "hooks/useUserMigrationNavi";
import { useMedia } from "react-use";
import useProviderBalance from "hooks/useProviderBalance";
import moment from "moment";
import useGetListStakedNFTv2 from "hooks/useGetListStakedNFTv2";
const FANTOMBRIDGE = 112;
const ARBITRUMBRIDGE = 110;
const BASEBRIDGE = 184;
const SONICBRIDGE = 332;
const TABS = [
  // {
  //   name: "MMY",
  //   icon: icMMY,
  // },
  // {
  //   name: "esMMY & vMMY",
  //   icon: icMMY,
  // },

  {
    name: "NFT",
    icon: icNFT,
  },
];
const OPBRIDGE = 111;

function getChainL0(chainId) {
  switch (chainId) {
    case FANTOM:
      return FANTOMBRIDGE;
    case OP:
      return OPBRIDGE;
    case ARBITRUM:
      return ARBITRUMBRIDGE;
    case BASE:
      return BASEBRIDGE;

    default:
      return FANTOMBRIDGE;
  }
}
function getChainId(chainLzId) {
  switch (chainLzId) {
    case FANTOMBRIDGE:
      return FANTOM;
    case OPBRIDGE:
      return OP;
    case ARBITRUMBRIDGE:
      return ARBITRUM;
    case BASEBRIDGE:
      return BASE;
    default:
      return FANTOM;
  }
}
const BridgeNavi = ({ setPendingTxns, pendingTxns, connectWallet }) => {
  const [isBridging, setIsBridging] = useState(false);
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { active, library, account, chainId: waletChainId } = useWeb3React();
  const [currentTab, setCurrentTab] = useState("NFT");
  const isMobile = useMedia("(max-width: 700px)");
  // const chains =
  //   currentTab === "MMY"
  //     ? [
  //         { chainId: FANTOMBRIDGE, name: "Fantom", img: icFantom, id: FANTOM },
  //         { chainId: OPBRIDGE, name: "Optimism", img: icOp, id: OP },
  //         { chainId: ARBITRUMBRIDGE, name: "Arbitrum", img: icArb, id: ARBITRUM },
  //         { chainId: BASEBRIDGE, name: "Base", img: icBase, id: BASE },
  //       ]
  //     : [
  //         // { chainId: FANTOMBRIDGE, name: "Fantom", img: icFantom, id: FANTOM },
  //         { chainId: OPBRIDGE, name: "Optimism", img: icOp, id: OP },
  //         { chainId: ARBITRUMBRIDGE, name: "Arbitrum", img: icArb, id: ARBITRUM },
  //       ];
  // const account = "0xaa305f47225f9c49a51786012448f9928e94b936";
  const shortenAddress = (address) => {
    if (address.length < 12) return address;
    return `${address.slice(0, 6)}...${address.slice(-6)}`;
  };
  const chains = [
    { chainId: FANTOMBRIDGE, name: "Fantom", img: icFantom, id: FANTOM },
    { chainId: FANTOMBRIDGE, name: "Fantom", img: icFantom, id: FANTOM },
  ];
  const ethBalance = useProviderBalance();
  const { chainId } = useChainId();
  const [isApproving, setIsApproving] = useState(false);
  const [isApprovingNFT, setIsApprovingNFT] = useState(false);
  const [fromChain, setFromChain] = useState(getChainL0(chainId));
  const [toChain, setToChain] = useState(
    chainId === OP ? FANTOMBRIDGE : chainId === ARBITRUM ? OPBRIDGE : ARBITRUMBRIDGE
  );
  const [receiver, setReceiver] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const [amount, setAmount] = useState("");
  const selectedFromChain = chains.find((x) => x.chainId === fromChain) || chains[0];
  const selectedToChain = chains.find((x) => x.chainId === toChain) || currentTab === chains[1];
  const nftClubAddress = getContract(chainId, "NFTClubV2");
  const bridgeReaderAddress = getContract(chainId, "Bridge");
  const bridgeNFTReaderAddress = getContract(chainId, "BridgeNaviNFT");
  const migrateReaderAddress = getContract(chainId, "EsMigrate");
  const NaviOFTAdapterAddress = getContract(chainId, "NaviOFTAdapter");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [row, setRows] = useState(100);
  const MAX_NUMBER_PAGE = 70;

  const [isVisible, setIsVisible] = useState(false);
  const [nftList, setNftList] = useState([]);
  const [modalNftList, setModaltNftList] = useState([]);
  const { histories } = useUserMigrationNavi(account, { page: 1, pageSize: 1000 });
  const historiesNFT = useBridgeApi(account, "NFT");
  const { listMyNFT } = useGetListStakedNFTv2();
  // console.log("????", listMyNFT);
  const toChainList = chains.filter((item) => item.chainId !== fromChain);

  useEffect(() => {
    setHistoryData([]);
  }, [currentTab]);
  useEffect(() => {
    setTimeout(() => {
      if (histories) {
        if (currentTab === "MMY" || currentTab === "esMMY & vMMY") {
          setHistoryData([...histories.filter((item) => item.type !== "NFT")]);
        } else setHistoryData([...histories.filter((item) => item.type === "NFT")]);
      }
    }, 300);
  }, [currentTab, histories, historiesNFT]);
  useEffect(() => {
    if (listMyNFT.length !== nftList.length && nftList.length > 0 && listMyNFT.length > 0) {
      setNftList([]);
      setModaltNftList([]);
    }
  }, [listMyNFT]);
  // useEffect(() => {
  //   let length = historyData.length;
  //   setRows(length);
  // }, [historyData]);
  // useEffect(() => {
  //   const oldFrom = fromChain;
  //   setFromChain(getChainL0(chainId));
  //   setToChain(
  //     oldFrom === getChainL0(chainId) ? (toChain === getChainL0(chainId) ? toChainList[0].chainId : toChain) : oldFrom
  //   );
  // }, [chainId, fromChain]);
  // useEffect(() => {
  //   if (currentTab !== "MMY")
  //     if (toChain !== toChainList[0].chainId) {
  //       setToChain(toChainList[0].chainId);
  //     }
  // }, [currentTab, toChainList]);
  useEffect(() => {
    if (listMyNFT && listMyNFT.length > 0) {
      const availableNFTs = listMyNFT.filter((x) => !x[2]);
      if (availableNFTs.length !== nftList.length) {
        let arrNft = [];
        for (let i = 0; i < listMyNFT.length; i++) {
          if (!listMyNFT[i][2]) arrNft.push({ id: listMyNFT[i][0], selected: false, power: listMyNFT[i][1] });
        }
        setNftList([...arrNft]);
      }
    } else {
      setNftList([]);
      setModaltNftList([]);
    }
  }, [listMyNFT]);
  // useEffect(() => {
  //   if (currentTab !== "MMY") {
  //     setNftList([]);
  //   }
  // }, [fromChain]);
  useEffect(() => {
    if (isVisible) {
      let arrModalNft = [];
      for (let i = 0; i < nftList.length; i++) {
        arrModalNft.push({ id: nftList[i].id, selected: nftList[i].selected, power: nftList[i].power });
      }
      setModaltNftList([...arrModalNft]);
    }
  }, [isVisible]);
  useEffect(() => {
    if (isVisible && nftList.length > 0 && modalNftList.length === 0) {
      let arrModalNft = [];
      for (let i = 0; i < nftList.length; i++) {
        arrModalNft.push({ id: nftList[i].id, selected: nftList[i].selected, power: nftList[i].power });
      }
      setModaltNftList([...arrModalNft]);
    }
  }, [nftList]);
  const handleChangeTab = (tab) => {
    // if (tab !== "MMY") {
    //   if (chainId === FANTOM) switchNetwork(toChain === OPBRIDGE ? ARBITRUM : OP, active);
    // }
    setCurrentTab(tab);
  };
  const handleSelectModalNft = (nft) => {
    let arrModal = [...modalNftList];
    for (let i = 0; i < arrModal.length; i++) {
      if (arrModal[i].id === nft.id) {
        arrModal[i].selected = !nft.selected;
      } else {
        arrModal[i].selected = false;
      }
    }
    setModaltNftList(arrModal);
  };
  const handleSelectAllModalNft = () => {
    let arrModal = [...modalNftList];
    for (let i = 0; i < arrModal.length; i++) {
      arrModal[i].selected = true;
    }
    setModaltNftList(arrModal);
  };
  const handleDeselectAllModalNft = () => {
    let arrModal = [...modalNftList];
    for (let i = 0; i < arrModal.length; i++) {
      arrModal[i].selected = false;
    }
    setModaltNftList(arrModal);
  };
  const handleContinueNftModal = () => {
    let arrModal = [];
    for (let i = 0; i < modalNftList.length; i++) {
      arrModal.push({ id: modalNftList[i].id, selected: modalNftList[i].selected, power: modalNftList[i].power });
    }
    setNftList(arrModal);
    setIsVisible(false);
  };
  const handleRemoveNft = (nft) => {
    let arrSelected = [];
    for (let i = 0; i < nftList.length; i++) {
      arrSelected.push({ id: nftList[i].id, selected: nftList[i].selected, power: nftList[i].power });
    }
    for (let i = 0; i < arrSelected.length; i++) {
      if (arrSelected[i].id === nft.id) {
        arrSelected[i].selected = false;
        break;
      }
    }
    setNftList(arrSelected);
  };
  const selectedNft = nftList.filter((x) => x.selected === true);
  const selectedModalNft = modalNftList.filter((x) => x.selected === true);
  const getAmountValue = () => {
    if (!amount) return expandDecimals(0, 18);
    else {
      if (!amount.includes(".")) return expandDecimals(amount, 18);
      else {
        let after = amount.split(".")[1];
        return expandDecimals(amount.replace(".", ""), 18 - after.length);
      }
    }
  };
  // const { data: estimateFee } = useSWR(
  //   [`Migrate:estSendFee:${[active]}`, chainId, migrateReaderAddress, "estimateSendFee"],
  //   {
  //     fetcher: contractFetcher(library, MigrateReader, [SONICBRIDGE, account, true, "0x"]),
  //   }
  // );
  const estimateFee = BigNumber.from(0);
  const { data: estimateFeeMMY } = useSWR(
    active && [`Migrate:estSendFee:${[active]}`, chainId, NaviOFTAdapterAddress, "estimateSendFee"],
    {
      fetcher: contractFetcher(library, NaviOFTAdapter, [
        SONICBRIDGE,
        account,
        getAmountValue(),
        false,
        "0x00010000000000000000000000000000000000000000000000000000000000055730",
      ]),
    }
  );

  const readerAddress = getContract(chainId, "Reader");
  const gmxAddress = getContract(chainId, "GMX");
  const esGmxAddress = getContract(chainId, "ES_GMX");
  const vMMYAddress = getContract(chainId, "vMMY");
  const bnGmxAddress = getContract(chainId, "BN_GMX");
  const glpAddress = getContract(chainId, "GLP");
  const stakedGmxTrackerAddress = getContract(chainId, "StakedGmxTracker");
  const bonusGmxTrackerAddress = getContract(chainId, "BonusGmxTracker");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const feeGmxTrackerAddress = getContract(chainId, "FeeGmxTracker");
  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");
  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const glpManagerAddress = getContract(chainId, "GlpManager");
  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const usdgAddress = getContract(chainId, "USDG");
  const walletTokens = [gmxAddress, esGmxAddress, glpAddress, stakedGmxTrackerAddress];
  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );
  // const { data: lzGMXBalances } = useSWR(
  //   [`Bridge:lzGMXBalances:${active}`, chainId, bridgeReaderAddress, "balanceOf", account || PLACEHOLDER_ACCOUNT],
  //   {
  //     fetcher: contractFetcher(library, Token, []),
  //   }
  // );
  // const { data: hasMigratedEsMMY } = useSWR(
  //   [`Bridge:hasMigratedEsMMY:${active}`, chainId, migrateReaderAddress, "hasMigrated", account || PLACEHOLDER_ACCOUNT],
  //   {
  //     fetcher: contractFetcher(library, MigrateReader, []),
  //   }
  // );
  const hasMigratedEsMMY = false;
  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  let gmxBalance = balanceData?.gmx;
  // if (chainId === OP) {
  //   gmxBalance = lzGMXBalances;
  // }

  const switchChains = () => {
    switchNetwork(getChainId(toChain), active);
  };
  const onInputChange = (e) => {
    const value = e.target.value;
    if (value.includes(".")) {
      let after = value.split(".")[1];
      if (after.length <= 18) setAmount(value);
    } else {
      setAmount(value);
    }
  };
  const { data: tokenAllowance } = useSWR(
    active && gmxAddress && [active, chainId, gmxAddress, "allowance", account, NaviOFTAdapterAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );
  const { balanceOf: balanceOfVMMY, decimals: vMMYDecimal } = useVMMYContract(chainId, active, account);
  const { data: tokenAllowanceVMMY } = useSWR(
    active && vMMYAddress && [active, chainId, vMMYAddress, "allowance", account, migrateReaderAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );
  // console.log("??????", { balanceOfVMMY, tokenAllowanceVMMY });

  const { AddressZero } = ethers.constants;

  let checkAmount = getAmountValue();
  const needApproval = gmxAddress !== AddressZero && tokenAllowance && checkAmount && checkAmount.gt(tokenAllowance);
  const needApprovalVMMY =
    vMMYAddress !== AddressZero && tokenAllowanceVMMY && balanceOfVMMY && balanceOfVMMY?.gt(tokenAllowanceVMMY);
  const ApproveTokens = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: gmxAddress,
      spender: NaviOFTAdapterAddress,
      chainId,
    });
  };
  const ApproveTokensVMMY = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: vMMYAddress,
      spender: migrateReaderAddress,
      chainId,
    });
  };
  // console.log("????", { chainId, gmxAddress, tokenAllowance, checkAmount });

  const { data: isApprovedNFT } = useSWR(
    account && [`StakeNFT:isApprovedForAll:${active && account}`, chainId, nftClubAddress, "isApprovedForAll"],
    {
      fetcher: contractFetcher(library, NFTClub, [account, bridgeNFTReaderAddress]),
    }
  );
  // let adapterParams = ethers.utils.solidityPack(["uint16", "uint256"], [1, 2000000]);

  // let adapterParams = ethers.utils.solidityPack(["uint16", "uint", "uint", "address"], [2, 200000, 0, account || "0x"]);
  let adapterParams = "0x";
  // console.log("????", adapterParams);
  const listIds = selectedNft.map((x) => Number(x.id));
  const idSelected = listIds.length > 0 ? listIds[0] : 1;
  const { data: estimateFeeNFT } = useSWR(
    listIds.length > 0 && [
      `Bridge:estimateSendBatchFee:${[active, idSelected]}`,
      chainId,
      bridgeNFTReaderAddress,
      "estimateSendFee",
    ],
    {
      fetcher: contractFetcher(library, BridgedNFTReader, [SONICBRIDGE, account, idSelected, false, adapterParams]),
    }
  );

  // const estimateFeeNFT = { nativeFee: chainId === OP ? expandDecimals(29, 14) : expandDecimals(29, 14) };
  const BridgeToken = () => {
    if (currentTab === "esMMY & vMMY") {
      const contract = new ethers.Contract(migrateReaderAddress, MigrateReader.abi, library.getSigner());
      // console.log("????", [account, toChain, account, getAmountValue(), AddressZero, account, "0x"]);
      setIsBridging(true);
      callContract(chainId, contract, "sendMigrationData", [SONICBRIDGE, account, AddressZero, "0x"], {
        value: estimateFee?.nativeFee,
        successMsg: `Migrate completed`,
        sentMsg: `Migrate submitted!`,
        failMsg: `Migrate failed.`,
        setPendingTxns,
        pendingTxns,
      })
        .then(async (res) => {
          await res.wait();
          setIsBridging(false);
        })
        .finally(() => {
          setIsBridging(false);
        });
    }
    if (currentTab === "MMY") {
      if (needApproval) {
        ApproveTokens();
        return;
      }
      const contract = new ethers.Contract(NaviOFTAdapterAddress, NaviOFTAdapter.abi, library.getSigner());
      // console.log("????", [account, toChain, account, getAmountValue(), AddressZero, account, "0x"]);
      setIsBridging(true);
      callContract(
        chainId,
        contract,
        "sendFrom",
        [
          account,
          SONICBRIDGE,
          account,
          getAmountValue(),
          account,
          AddressZero,
          "0x00010000000000000000000000000000000000000000000000000000000000055730",
        ],
        {
          value: estimateFeeMMY?.nativeFee,
          successMsg: `Migrate completed`,
          sentMsg: `Migrate submitted!`,
          failMsg: `Migrate failed.`,
          setPendingTxns,
          pendingTxns,
        }
      )
        .then(async (res) => {
          await res.wait();
          setAmount("");
          setIsBridging(false);
        })
        .finally(() => {
          setIsBridging(false);
          setAmount("");
        });
    } else {
      const contract = new ethers.Contract(bridgeNFTReaderAddress, BridgedNFTReader.abi, library.getSigner());
      // console.log("?????", { fee: estimateFeeNFT?.nativeFee });
      setIsBridging(true);
      callContract(
        chainId,
        contract,
        "sendFrom",
        [account, SONICBRIDGE, account, listIds[0], account, account, adapterParams],
        {
          value: estimateFeeNFT?.nativeFee,
          successMsg: `Migrate completed`,
          sentMsg: `Migrate submitted!`,
          failMsg: `Migrate failed.`,
          setPendingTxns,
          pendingTxns,
        }
      )
        .then(async (res) => {
          await res.wait();
          setIsBridging(false);
        })
        .finally(() => {
          setIsBridging(false);
        });
    }
  };
  const handleSelectDropDownFrom = (chainId) => {
    if (fromChain !== chainId) switchNetwork(getChainId(chainId), active);
  };
  const handleSelectDropDownTo = (chainId) => {
    setToChain(chainId);
  };
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const getBridgeContract = (selectedChainId) => {
    if (currentTab === "MMY") return getContract(chains.find((x) => x.chainId === selectedChainId || 112).id, "Bridge");
    else if (chainId === BASE) return "";
    else return getContract(chains.find((x) => x.chainId === selectedChainId || 112).id, "BridgeNFT");
  };
  const isBridgeDisabled = () => {
    if (currentTab === "MMY") {
      if (!amount || !account) return true;
      if (isApproving) return true;
      const valueAmount = getAmountValue();
      if (valueAmount.gt(gmxBalance || 0)) return true;
      if (valueAmount.lte(0)) return true;
      if (estimateFeeMMY && ethBalance && estimateFeeMMY.nativeFee.gte(ethBalance)) return true;
    } else if (currentTab === "NFT") {
      if (selectedNft.length <= 0) return true;
      if (estimateFeeNFT && ethBalance && estimateFeeNFT.nativeFee.gte(ethBalance)) return true;
    } else {
      if (
        vestingData?.gmxVesterVestedAmount?.gt(0) ||
        vestingData?.glpVesterVestedAmount?.gt(0) ||
        totalVested?.gt(0) ||
        processedData?.esGmxInStakedGmx?.gt(0) ||
        feeGmxTrackerData?.gt(0)
      )
        return true;
      if (hasMigratedEsMMY) return true;
      if (!hasEsBalance) return true;
      if (estimateFee && ethBalance && estimateFee.nativeFee.gte(ethBalance)) return true;
    }
    if (isBridging) return true;

    return false;
  };
  const getMMyBridgeText = () => {
    const valueAmount = getAmountValue();
    if (valueAmount.lte(0)) return "Enter an amount";
    if (valueAmount.gt(gmxBalance || 0)) return "Insufficient MMY balance";
    if (isBridging) return "Migrating...";
    if (estimateFeeMMY && ethBalance && estimateFeeMMY.nativeFee.gt(ethBalance))
      return `Insufficient ${nativeTokenSymbol}  balance`;
    if (isApproving) return "Approving...";
    if (needApproval) {
      return "Approve";
    }
    return "Migrate";
  };
  const handleMaxBalance = () => {
    if (gmxBalance && gmxBalance.gt(0)) setAmount(formatAmount(gmxBalance || 0, 18, 18, false));
    else setAmount("0.0000");
  };
  const getRecieveToken = () => {
    let result = amount;
    if (!amount) return "--";
    if (amount.includes(".")) {
      const after = amount.split(".")[1];
      if (after.length <= 4) {
        const gap = 4 - after.length;
        for (let i = 0; i < gap; i++) {
          result += "0";
        }
        return numberWithCommas(result);
      } else {
        const result = after.slice(0, 4);
        return numberWithCommas(amount.split(".")[0] + "." + result);
      }
    }
    return numberWithCommas(amount + ".0000");
  };

  const showData =
    historyData.length > 0
      ? historyData.slice((currentPage - 1) * rowsPerPage, Math.min(currentPage * rowsPerPage, historyData.length))
      : [];
  const onApproveNFT = () => {
    setIsApprovingNFT(true);
    const contract = new ethers.Contract(nftClubAddress, NFTClub.abi, library.getSigner());
    callContract(chainId, contract, "setApprovalForAll", [bridgeNFTReaderAddress, true], {
      sentMsg: `Approve NFT submitted!`,
      failMsg: `Approve NFT failed.`,
      successMsg: `Approve NFT completed!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsApprovingNFT(false);
      });
  };
  const renderNFTTransaction = (item) => {
    const listIds = item?.amount.split(",");
    if (listIds.length === 1) {
      return `Mummy #${listIds[0]}`;
    } else {
      return (
        <div className="nft-tool-tip">
          <Tooltip
            handle={`${listIds.length} NFTs`}
            position="right-bottom"
            renderContent={() => {
              return (
                <div className="nft-tooltip-container">
                  {listIds.map((id) => (
                    <div key={id} className="nft-line">
                      Mummy #{id}
                    </div>
                  ))}
                  <div />
                </div>
              );
            }}
          />
        </div>
      );
    }
  };
  const gmxVesterAddress = getContract(chainId, "GmxVester");

  const depositTokens = [
    gmxAddress,
    esGmxAddress,
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    bnGmxAddress,
    glpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedGmxTrackerAddress,
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    feeGmxTrackerAddress,
    feeGmxTrackerAddress,
    feeGlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    feeGmxTrackerAddress,
    stakedGlpTrackerAddress,
    feeGlpTrackerAddress,
  ];
  const glpVesterAddress = getContract(chainId, "GlpVester");
  const vesterAddresses = [gmxVesterAddress, glpVesterAddress];
  const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];
  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );
  const { data: stakingInfo } = useSWR(
    [
      `StakeV2:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );
  const { data: vestingInfo } = useSWR(
    [
      `StakeV2:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);
  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 10000,
  });
  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }
  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );
  const { data: stakedGmxSupply } = useSWR(
    [
      `StakeV2:stakedGmxSupply:${active}:${stakedGmxTrackerAddress}`,
      chainId,
      gmxAddress,
      "balanceOf",
      stakedGmxTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );
  const { data: feeGmxTrackerData } = useSWR(
    [
      `StakeV2:feeGmxTrackerAddress:${active}:${feeGmxTrackerAddress}`,
      chainId,
      feeGmxTrackerAddress,
      "balanceOf",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, FeeGmxTracker),
      refreshInterval: 10000,
    }
  );

  const { gmxPrice } = useGmxPrice(chainId, { arbitrum: chainId === FANTOM ? library : undefined }, active);
  const { data: totalSupplies } = useSWR(
    [
      `StakeV2:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );
  let totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalSupply = totalSupplies[1];
  }
  let gmxSupply = totalSupply;
  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmxSupply,
    gmxPrice,
    gmxSupply
  );
  const esMMYBalance = balanceData?.esGmx || BigNumber.from(0);
  const hasEsBalance = esMMYBalance.gt(0) || (balanceOfVMMY && balanceOfVMMY.gt(0));
  // console.log("??????", processedData);
  const {
    getTotalVested: [totalVested],
  } = useMMYFarmContractMulticall(chainId, active, account, library);

  useEffect(() => {
    if (String(window.location.hash).includes("nft")) {
      handleChangeTab("NFT");
    }
  }, []);
  const getStatusName = (status) => {
    switch (status) {
      case "BLOCKED":
        return "Failed";
      case "FAILED":
        return "Failed";
      case "DELIVERED":
        return "Delivered";
      case "INFLIGHT":
        return "Inflight";
      default:
        return "Inflight";
    }
  };
  return (
    <Wrapper className={lightThemeClassName}>
      <div className={`bridge-wrapper ${lightThemeClassName}`}>
        <div className="page-title">Migrate To Navigator</div>
        <div className="page-desc">Bridge $MMY and NFTs seamlessly and securely across blockchains.</div>
        <div className="power-by">
          Powered by <img alt="" src={isLightTheme ? LayerZeroIconLight : LayerZeroIcon} />
        </div>
        <div className="tabs-container">
          {TABS.map((item) => (
            <Link
              className={`tab-content ${currentTab === item.name ? "tab-content-active" : ""}`}
              key={item.name}
              onClick={() => handleChangeTab(item.name)}
              to={`/migrate-navi/${item.name.toLowerCase()}`}
            >
              <img alt="" src={item.icon} />
              <span>{item.name}</span>
            </Link>
          ))}
        </div>
        <div className="form-bridge">
          {/* <div className="chains">
            <div className="chain-select-dropdown">
              <div className="dropdown-name">From</div>
              <Menu>
                <Menu.Button as="div">
                  <div className="selected-chain">
                    <div className="chain-info">
                      <img alt="" src={selectedFromChain.img} className="token-chain" />{" "}
                      <div className="chain-name">{selectedFromChain.name}</div>
                    </div>
                    <img alt="" src={isLightTheme ? IcDownLight : IcDown} className="ic_down" />
                  </div>
                </Menu.Button>
                <div>
                  <Menu.Items as="div" className="menu-items">
                    {chains.map((item) => (
                      <Menu.Item key={item.chainId} onClick={() => handleSelectDropDownFrom(item.chainId)}>
                        <div className={`menu-item ${item.chainId === fromChain ? "menu-item--active" : ""}`}>
                          <img alt="" src={item.img} /> <div className="chain-name">{item.name}</div>
                        </div>
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </div>
              </Menu>
            </div>
            <div className={`swap-ball`} onClick={switchChains}>
              <IoMdSwap className="swap-ball-icon" />
            </div>
            <div className="chain-select-dropdown">
              <div className="dropdown-name">To</div>
              <Menu>
                <Menu.Button as="div">
                  <div className="selected-chain">
                    <div className="chain-info">
                      <img alt="" src={selectedToChain.img} className="token-chain" />{" "}
                      <div className="chain-name">{selectedToChain.name}</div>
                    </div>
                    <img alt="" src={isLightTheme ? IcDownLight : IcDown} className="ic_down" />
                  </div>
                </Menu.Button>
                <div>
                  <Menu.Items as="div" className="menu-items">
                    {toChainList.map((item) => (
                      <Menu.Item key={item.chainId} onClick={() => handleSelectDropDownTo(item.chainId)}>
                        <div className={`menu-item ${item.chainId === toChain ? "menu-item--active" : ""}`}>
                          <img alt="" src={item.img} /> <div className="chain-name">{item.name}</div>
                        </div>
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </div>
              </Menu>
            </div>
          </div> */}
          <div className="transfer-amount" style={currentTab === "esMMY & vMMY" ? { marginTop: 0 } : {}}>
            <div className="balance-info">
              <div>
                {currentTab === "MMY"
                  ? "Amount"
                  : currentTab === "esMMY & vMMY"
                  ? "Conditions"
                  : "Select your NFT to bridge (Currently, we only support bridging one NFT at a time)"}
              </div>
              {currentTab === "MMY" ? (
                <div className="balance" onClick={handleMaxBalance}>
                  Balance: <span>{formatAmount(gmxBalance, 18, 4, true)}</span>
                </div>
              ) : (
                <div>
                  {selectedNft.length > 0 && (
                    <div className="cusor-pointer edit-text green-text" onClick={() => setIsVisible(true)}>
                      Edit
                    </div>
                  )}
                </div>
              )}
            </div>
            {currentTab === "MMY" ? (
              <input placeholder="0.00" className="amount" type="number" value={amount} onChange={onInputChange} />
            ) : currentTab === "NFT" ? (
              <div className="nft-selected-container">
                {selectedNft.map((item) => (
                  <SelectedNftItem item={item} onRemove={handleRemoveNft} />
                ))}
                {selectedNft.length < 1 && (
                  <div className="add-nft-item" onClick={() => setIsVisible(true)}>
                    <img alt="" src={isLightTheme ? icPlusLight : icPlus} />
                  </div>
                )}
              </div>
            ) : (
              // <input
              //   placeholder="Enter Address"
              //   className="amount"
              //   value={receiver}
              //   onChange={(e) => setReceiver(e.target.value)}
              // />
              <></>
            )}
            {/* {currentTab === "MMY" && (
              <div className="BeginAccountTransfer-validations">
                <ValidationRow isValid={!hasVestedGmx}>
                  <span>Sender has withdrawn all tokens from MMY Vesting Vault</span>
                </ValidationRow>
                <ValidationRow isValid={!processedData?.gmxInStakedGmx?.gt(0)}>
                  <span>Sender has unstaked MMY tokens</span>
                </ValidationRow>
                <ValidationRow isValid={!hasStakedNavi}>
                  <span>Receiver has not staked NAVI tokens before</span>
                </ValidationRow>
              </div>
            )} */}
            {currentTab === "esMMY & vMMY" && (
              <div className="BeginAccountTransfer-validations">
                <ValidationRow
                  isValid={
                    !vestingData?.gmxVesterVestedAmount?.gt(0) &&
                    !vestingData?.glpVesterVestedAmount?.gt(0) &&
                    !totalVested?.gt(0)
                  }
                >
                  <span>
                    Sender has withdrawn all tokens from MMY, MLP, vMMY vaults
                    {/* {vestingData?.gmxVesterVestedAmount?.gt(0) ||
                      vestingData?.glpVesterVestedAmount?.gt(0) ||
                      (totalVested?.gt(0) && (
                        <span>
                          (<Link to={"/earn-v2/mslp"}>MSLP</Link> & <Link to={"/earn-v2/vest"}>Vest</Link>)
                        </span>
                      ))} */}
                  </span>
                </ValidationRow>
                <ValidationRow isValid={!processedData?.esGmxInStakedGmx?.gt(0) && !feeGmxTrackerData?.gt(0)}>
                  <span>Sender has unstaked all tokens from MMY and esMMY pools</span>
                  {/* {processedData?.esGmxInStakedGmx?.gt(0) && (
                    <span>
                      (<Link to={"/earn-v2/mmy-esmmy"}>esMMY</Link>)
                    </span>
                  )} */}
                </ValidationRow>
              </div>
            )}
          </div>
          <div className="recieve-info" style={currentTab === "esMMY & vMMY" ? { marginTop: "12px" } : {}}>
            {currentTab === "esMMY & vMMY" && (
              <div>
                Balance:{" "}
                <span className="white-text">
                  {formatAmount(esMMYBalance.add(balanceOfVMMY || 0), 18, 2, true)} (
                  {formatAmount(esMMYBalance || 0, 18, 2, true)} esMMY & {formatAmount(balanceOfVMMY || 0, 18, 2, true)}{" "}
                  vMMY)
                </span>
              </div>
            )}
            <div>
              You will receive:{" "}
              {currentTab === "MMY" ? (
                <span className="white-text">{getRecieveToken()} NAVI</span>
              ) : currentTab === "NFT" ? (
                <span className="white-text">
                  {selectedNft.length} NFT{selectedNft.length > 1 ? "(s)" : ""}
                </span>
              ) : (
                <span className="white-text">
                  {formatAmount(esMMYBalance.add(balanceOfVMMY || 0), 18, 2, true)} esNAVI
                </span>
              )}
            </div>
            <div>
              <Tooltip
                handle="Migrate fee:"
                position="left-bottom"
                renderContent={() => <div>Transaction Fees charged by LayerZero</div>}
              />{" "}
              {currentTab === "esMMY & vMMY" ? (
                <span className="white-text">
                  {estimateFee ? formatAmount(estimateFee?.nativeFee, 18, 4, true) : "--"} {nativeTokenSymbol}
                </span>
              ) : currentTab === "MMY" ? (
                <span className="white-text">
                  {estimateFeeMMY ? formatAmount(estimateFeeMMY?.nativeFee, 18, 4, true) : "--"} {nativeTokenSymbol}
                </span>
              ) : (
                <span className="white-text">
                  {estimateFeeNFT ? formatAmount(estimateFeeNFT?.nativeFee, 18, 4, true) : "--"} {nativeTokenSymbol}
                </span>
              )}
            </div>
            {account && (
              <div className="reciever">
                <Tooltip
                  handle="Receiver:"
                  position="left-bottom"
                  renderContent={() => <div>This is the destination address of the network</div>}
                />{" "}
                <span>
                  <img
                    alt=""
                    src={importImage(`ic_s_24.svg`)}
                    className="reciver-chain-name"
                    style={{ marginRight: "4px" }}
                  />
                  <ExternalLink href={getExplorerUrl(SONIC) + "address/" + account}>
                    <span className="address-text">
                      {isMobile ? shortenAddress(account) : account}{" "}
                      <img alt="" src={isLightTheme ? icScanLight : icScan} />
                    </span>
                  </ExternalLink>
                </span>
              </div>
            )}
          </div>
          {currentTab === "MMY" ? (
            <div className="actions">
              {!active ? (
                <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                  Connect Wallet
                </button>
              ) : (
                <button
                  className="App-button-option App-card-option"
                  disabled={isBridgeDisabled()}
                  onClick={() => BridgeToken()}
                >
                  {getMMyBridgeText()}
                </button>
              )}
            </div>
          ) : currentTab === "NFT" ? (
            <div className="actions">
              {!active ? (
                <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                  Connect Wallet
                </button>
              ) : (
                <button
                  className="App-button-option App-card-option"
                  disabled={isBridgeDisabled()}
                  onClick={() => BridgeToken()}
                >
                  {isBridging
                    ? "Migrating..."
                    : estimateFeeNFT && ethBalance && estimateFeeNFT.nativeFee.gte(ethBalance)
                    ? `Insufficient ${nativeTokenSymbol}  balance`
                    : modalNftList.length === 0
                    ? "No NFTs to migrate"
                    : "Migrate"}
                </button>
              )}
            </div>
          ) : (
            <div className="actions">
              {!active ? (
                <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                  Connect Wallet
                </button>
              ) : needApprovalVMMY ? (
                <button className="App-button-option App-card-option" onClick={() => ApproveTokensVMMY()}>
                  {isApproving ? "Approving..." : "Approve"}
                </button>
              ) : (
                <button
                  className="App-button-option App-card-option"
                  disabled={isBridgeDisabled()}
                  onClick={() => BridgeToken()}
                >
                  {hasMigratedEsMMY
                    ? "You've migrated before"
                    : !hasEsBalance
                    ? "No tokens to migrate"
                    : isBridging
                    ? "Migrating..."
                    : estimateFee && ethBalance && estimateFee.nativeFee.gt(ethBalance)
                    ? `Insufficient ${nativeTokenSymbol}  balance`
                    : "Migrate"}
                </button>
              )}
            </div>
          )}
        </div>
        <div className="down-content">
          <div className="page-title">Transaction History</div>
          <div className="transaction-table">
            <div className="transaction-line transaction-header">
              <div className="table-column">Time</div>
              <div className="table-column">Migrate</div>
              <div className="table-column">
                {currentTab === "MMY" || currentTab === "esMMY & vMMY" ? "Amount" : "NFT"}
              </div>
              <div className="table-column">Status</div>
              <div className="table-column column-view">View</div>
            </div>
            <div className="transaction-content">
              {(!historyData || historyData?.length === 0) && <div className="no-data">No transactions data</div>}
              {historyData &&
                showData.map((item) => (
                  <div className="transaction-line transaction-row" key={item.fromTx}>
                    <div className="table-column">
                      {item?.fromTimestamp ? (
                        // new Intl.DateTimeFormat("en-US", {
                        //   year: "numeric",
                        //   month: "short",
                        //   day: "2-digit",
                        //   hour: "2-digit",
                        //   minute: "2-digit",
                        //   hour12: false,
                        // }).format(new Date(item.fromTimestamp * 1000))
                        moment(item.fromTimestamp * 1000).format("DD MMM YYYY, HH:mm")
                      ) : (
                        <div className="skeleton-box" style={{ width: "60px" }} />
                      )}
                    </div>
                    <div className="table-column">
                      {item.type === "TOKEN"
                        ? "MMY"
                        : `${BigNumber.from(item?.esMMY || 0).gt(0) ? "esMMY" : ""}${
                            BigNumber.from(item?.esMMY || 0).gt(0) && BigNumber.from(item?.vMMY || 0).gt(0)
                              ? " & "
                              : "NFT"
                          }${BigNumber.from(item?.vMMY || 0).gt(0) ? "vMMY" : ""}`}
                    </div>
                    {item.type === "TOKEN" || item.type === "ESV" ? (
                      <div className="table-column">
                        {item.amount
                          ? formatAmount(item.amount, 18, 4, true)
                          : `${formatAmount(
                              BigNumber.from(item?.esMMY || 0).add(BigNumber.from(item?.vMMY || 0)),
                              18,
                              4,
                              true
                            )}`}
                      </div>
                    ) : (
                      <div className="table-column">{renderNFTTransaction(item)}</div>
                    )}
                    <div
                      className={`table-column ${item?.status.toLowerCase()}`}
                      style={{ textTransform: "capitalize" }}
                    >
                      {getStatusName(item?.status)}{" "}
                    </div>
                    <div className="table-column column-view">
                      <ExternalLink href={getLayerZeroExplorer(chainId) + "/tx/" + item.fromTx}>
                        <img alt="" src={isLightTheme ? icScanLight : icScan} />
                      </ExternalLink>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="transaction-table-mobile">
            {!historyData || (historyData.length === 0 && <div className="no-data">No transactions data</div>)}
            {historyData &&
              showData.map((item) => (
                <div className="table-mobile-content" key={item.fromTx}>
                  <div className="content-line">
                    <div className="title">Time</div>
                    <div className="value">
                      {item?.fromTimestamp ? (
                        new Date(item.fromTimestamp * 1000).toLocaleString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })
                      ) : (
                        <div className="skeleton-box" style={{ width: "60px" }} />
                      )}
                    </div>
                  </div>
                  <div className="content-line">
                    <div className="title">Migrate</div>
                    <div className="value">
                      {item.amount
                        ? "MMY"
                        : `${BigNumber.from(item?.esMMY || 0).gt(0) ? "esMMY" : ""}${
                            BigNumber.from(item?.esMMY || 0).gt(0) && BigNumber.from(item?.vMMY || 0).gt(0) ? " & " : ""
                          }${BigNumber.from(item?.vMMY || 0).gt(0) ? "vMMY" : "NFT"}`}
                    </div>
                  </div>
                  <div className="content-line">
                    <div className="title">
                      {currentTab === "MMY" || currentTab === "esMMY & vMMY" ? "Amount" : "NFT"}
                    </div>
                    {item.type === "TOKEN" || item.type === "ESV" ? (
                      <div className="value">
                        {item.amount
                          ? formatAmount(item.amount, 18, 4, true)
                          : `${formatAmount(
                              BigNumber.from(item?.esMMY || 0).add(BigNumber.from(item?.vMMY || 0)),
                              18,
                              4,
                              true
                            )}`}
                      </div>
                    ) : (
                      <div className="value">{renderNFTTransaction(item)}</div>
                    )}
                  </div>
                  <div className="content-line">
                    <div className="title">Status</div>
                    <div className={`value ${item?.status.toLowerCase()}`} style={{ textTransform: "capitalize" }}>
                      {getStatusName(item?.status)}{" "}
                    </div>
                  </div>
                  <div className="content-line">
                    <div className="title">View</div>
                    <div className={`value`} style={{ textTransform: "capitalize" }}>
                      <ExternalLink href={getLayerZeroExplorer(chainId) + "/tx/" + item.fromTx}>
                        <img alt="" src={isLightTheme ? icScanLight : icScan} />
                      </ExternalLink>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {historyData && historyData.length > 0 && (
            <div className="pagination">
              <Pagination
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setCurrentPage={setCurrentPage}
                maxNumberOfPage={MAX_NUMBER_PAGE}
                row={row}
              />
            </div>
          )}
        </div>
        <ModalWrapper className="StakeModal">
          <Modal isVisible={isVisible} className="secondary" setIsVisible={setIsVisible} label="Select NFT">
            {modalNftList.length > 0 && (
              <div>
                <div className="nft-select-container">
                  <div className="selected-info">
                    <div className="available">
                      Available: <span>{modalNftList.length}</span>
                    </div>
                  </div>
                  {/* <div className="info">
                    <div className="selected">
                      Selected: <span>{selectedModalNft.length}</span>
                    </div>
                    <div className="select-btn" onClick={() => handleSelectAllModalNft()}>
                      Select All
                    </div>
                    <div className="select-btn" onClick={() => handleDeselectAllModalNft()}>
                      Deselect All
                    </div>
                  </div> */}
                </div>
                <div className="nft-list">
                  {modalNftList.map((item) => (
                    <div
                      className={`list-item ${item.selected ? "list-item-selected" : ""}`}
                      key={item.id}
                      onClick={() => handleSelectModalNft(item)}
                    >
                      <div className="checkbox">
                        {isLightTheme ? (
                          <img alt="" src={item?.selected ? icCheckboxCheckedLight : icCheckboxLight} />
                        ) : (
                          <img alt="" src={item?.selected ? icCheckboxChecked : icCheckbox} />
                        )}
                      </div>
                      <img alt="" src={`${getLinkCDN(chainId)}${item.id}.png`} className="main-nft-modal" />
                      <div className="nft-info">
                        <div className="title">Mummy #{item.id}</div>
                        <div className="info">
                          <img alt="" src={isLightTheme ? icBoltLight : icBolt} />
                          <span>{formatNumber(item.power)}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {modalNftList.length === 0 && (
              <div className="empty-state">
                <img alt="" src={icEmpty} className="empty-img" />
                <div>You don't have any Mummy NFT.</div>
                <Link className="get-your-nft" to="/nft">
                  Get your Mummy NFT
                  <img alt="" src={isLightTheme ? icScanLight : icScan} className="scan-img" />
                </Link>
              </div>
            )}
            <div className="actions">
              {!active ? (
                <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                  Connect Wallet
                </button>
              ) : !isApprovedNFT ? (
                <button className="App-button-option App-card-option" onClick={() => onApproveNFT()}>
                  {isApprovingNFT ? "Approving..." : "Approve"}
                </button>
              ) : (
                <button
                  className="App-button-option App-card-option"
                  onClick={() => handleContinueNftModal()}
                  disabled={selectedModalNft.length === 0 && selectedNft.length === 0}
                >
                  {selectedModalNft.length === 0 && selectedNft.length === 0 ? "Select NFT" : "Continue"}
                </button>
              )}
            </div>
          </Modal>
        </ModalWrapper>
        <Footer />
      </div>
    </Wrapper>
  );
};
export default BridgeNavi;

const ModalWrapper = styled.div`
  .Modal-body {
    padding-left: 24px !important;
    padding-right: 24px !important;

    @media (max-width: 767px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 16px !important;
    }
  }
  .Modal-content {
    width: 592px;
  }
`;

const Wrapper = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: contain;

  &.theme--light {
    .edit-text {
      color: #02b27f !important;

      &:hover {
        color: #03805b !important;
      }
    }
  }
`;
