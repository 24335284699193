import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { Trans, t } from "@lingui/macro";
import CountUp from "react-countup";

import { PLACEHOLDER_ACCOUNT, USD_DECIMALS, isActiveV2 } from "lib/legacy";
import { formatAmount, parseValue } from "lib/numbers";

import { ARBITRUM, BASE, FANTOM, OP, getChainName } from "config/chains";
import arb24Icon from "img/ic_arbitrum_24.svg";
import base24Icon from "img/ic_base_24.svg";

import op24Icon from "img/ic_op_24.svg";
import ftm24Icon from "img/ic_ftm_24.svg";
import opImg from "img/ic_op_40.svg";
import baseImg from "img/ic_base_40.svg";
import fantomImg from "img/ic_ftm_40.svg";
import stats1 from "img/dashboard/tvl.svg";
import stats2 from "img/dashboard/tvolumn.svg";
import stats3 from "img/dashboard/tfee.svg";
import since from "img/dashboard/since.svg";
import longPosition from "img/dashboard/longPosition.svg";
import shortPosition from "img/dashboard/shortPosition.svg";
import stats1Light from "img/dashboard/tvlLight.svg";
import stats2Light from "img/dashboard/tvolumnLight.svg";
import stats3Light from "img/dashboard/tfeeLight.svg";
import sinceLight from "img/dashboard/sinceLight.svg";
import longPositionLight from "img/dashboard/longPositionLight.svg";
import shortPositionLight from "img/dashboard/shortPositionLight.svg";
import VolumeChart from "./VolumeChart";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import TooltipComponent from "components/Tooltip/Tooltip";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { formatDate } from "lib/dates";
import { useMediaQuery } from "react-responsive";

import { StatsContext } from "contexts/StatsProvider";
import { BigNumber } from "ethers";
import { useThemeContext } from "contexts/ThemeProvider";

const STATS_CHAINS = [
  {
    chainId: FANTOM,
    img: ftm24Icon,
  },
  {
    chainId: OP,
    img: opImg,
  },
  {
    chainId: ARBITRUM,
    img: arb24Icon,
  },
  {
    chainId: BASE,
    img: baseImg,
  },
];

const Stats = ({ chainId, active }) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const isTablet = useMediaQuery({ query: "(max-width: 1023px) and (min-width: 767px)" });
  const totalStatsStartDate = {
    [FANTOM]: `05 Dec 2022`,
    [OP]: `03 Mar 2023`,
    [ARBITRUM]: `29 Jun 2023`,
    [BASE]: `16 Oct 2023`,
  };
  const chainName = getChainName(chainId);

  const {
    v2,
    totalAllVersion,
    totalValueLocked,
    totalFees,
    totalTradingVolume,
    longOpenInterest,
    shortOpenInterest,
    feeSinceToNow,
  } = useContext(StatsContext);

  return (
    <Wrapper className={lightThemeClassName}>
      <Title>
        <span>Stats</span> {chainId === OP && <img src={op24Icon} alt="op24Icon" />}
        {chainId === FANTOM && <img src={ftm24Icon} alt="ftm24Icon" />}
        {chainId === ARBITRUM && <img src={arb24Icon} alt="arb24Icon" />}
        {chainId === BASE && <img src={base24Icon} alt="base24Icon" />}
      </Title>
      <Description>
        <div>
          {chainName} Total Stats start from {totalStatsStartDate[chainId]}.<br /> For detailed stats:{" "}
          <ExternalLink target="_self" href="/#/analytics">
            Analytics
          </ExternalLink>
          {/* {isActiveV2(chainId) ? "| " : ""}
          {isActiveV2(chainId) && (
            <ExternalLink target="_self" href="/#/analytic-v2/overview">
              V2
            </ExternalLink>
          )} */}
          {/* {chainId !== ARBITRUM && <ExternalLink href="https://app.mummy.finance/#/analytic/overview">Analytics page</ExternalLink>}
          {chainId === ARBITRUM && <ExternalLink href="https://stats.mummy.finance/arbitrum">Stats page</ExternalLink>} */}
          .
        </div>
        {/* {chainId === FANTOM && (
          <ExternalLink href="https://stats.mummy.finance">https://stats.mummy.finance</ExternalLink>
        )}
        {chainId === OP && (
          <ExternalLink href="https://stats.mummy.finance/optimism">https://stats.mummy.finance/optimism</ExternalLink>
        )} */}

        {/* <ExternalLink href="https://www.mummy.finance/#/analytic/">https://www.mummy.finance/#/analytic</ExternalLink>. */}
      </Description>
      <div
        className={`${
          chainId === OP ? "op-content" : chainId === ARBITRUM ? "arb-content" : chainId === BASE ? "base-content" : ""
        } background-chain`}
      >
        {chainId === FANTOM && <ChainImg src={fantomImg} alt="Chain img" />}
        {chainId === OP && <ChainImg src={opImg} alt="Chain img" />}
        {chainId === BASE && <ChainImg src={baseImg} alt="Chain img" />}
        {chainId === ARBITRUM && <ChainImg src={chainId === FANTOM ? fantomImg : arb24Icon} alt="Chain img" />}
      </div>
      <StatsContainer>
        <StatBlock>
          <div className="stats-img">
            <img src={isLightTheme ? stats1Light : stats1} alt="img" />
          </div>
          <div className="stats-info">
            <div className="label">Total Value Locked</div>
            <div className="stats-value">
              {totalValueLocked?.[chainId] ? (
                <TooltipComponent
                  position="left-bottom"
                  handle={
                    <CountUp
                      end={
                        chainId === FANTOM && totalValueLocked?.["totalV2"]
                          ? parseFloat(
                              formatAmount(
                                totalValueLocked?.[chainId]?.add(totalValueLocked?.["totalV2"]),
                                USD_DECIMALS,
                                0
                              )
                            )
                          : formatAmount(
                              totalValueLocked?.[chainId]?.add(totalValueLocked?.v2?.[chainId] || 0),
                              USD_DECIMALS,
                              0
                            )
                      }
                      separator=","
                      prefix="$"
                    />
                  }
                  className="excep"
                  // renderContent={() => (
                  //   <CustomContent>
                  //     <div>
                  //       The total value of MMY staked and MLP pool on:
                  //       <div className="row">
                  //         Fantom:{" "}
                  //         <span className="value">
                  //           ${formatAmount(totalValueLocked?.[FANTOM], USD_DECIMALS, 0, true)}
                  //         </span>
                  //       </div>
                  //       <div className="row">
                  //         Fantom legacy:{" "}
                  //         <span className="value">
                  //           ${formatAmount(totalValueLocked?.[FANTOM_LEGACY], USD_DECIMALS, 0, true)}
                  //         </span>
                  //       </div>
                  //       <div className="row">
                  //         Optimism:{" "}
                  //         <span className="value">${formatAmount(totalValueLocked?.[OP], USD_DECIMALS, 0, true)}</span>
                  //       </div>
                  //       <div className="row">
                  //         Arbitrum:{" "}
                  //         <span className="value">${formatAmount(totalValueLocked?.[ARBITRUM], USD_DECIMALS, 0, true)}</span>
                  //       </div>
                  //       <div className="row">
                  //         Base:{" "}
                  //         <span className="value">${formatAmount(totalValueLocked?.[BASE], USD_DECIMALS, 0, true)}</span>
                  //       </div>
                  //     </div>
                  //     <div className="divider"></div>
                  //     <div className="row">
                  //       Total:{" "}
                  //       <span className="value">${formatAmount(totalValueLocked?.["total"], USD_DECIMALS, 0, true)}</span>
                  //     </div>
                  //   </CustomContent>
                  // )}
                  renderContent={() => (
                    <CustomContent>
                      <div className="k-v-box">
                        <div className="k-v-label">Total value locked</div>
                        <div className="k-v-value">
                          ${formatAmount(totalValueLocked?.["totalAll"], USD_DECIMALS, 0, true)}
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="tooltip-row">
                        <div className="k-v-box">
                          <div className="k-v-label">
                            Total value locked <span className="ver">V1</span>
                          </div>
                          <div className="k-v-value">
                            ${formatAmount(totalValueLocked?.["total"], USD_DECIMALS, 0, true)}
                          </div>
                        </div>
                        <div className="chains">
                          {STATS_CHAINS.map((chain) => {
                            return (
                              <div key={chain.chainId} className="chain">
                                <img className="chain-img" src={chain.img} alt={chain.chainId} />
                                <div className="chain-value">
                                  ${formatAmount(totalValueLocked?.[chain.chainId], USD_DECIMALS, 0, true)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="tooltip-row">
                        <div className="k-v-box">
                          <div className="k-v-label">
                            Total value locked <span className="ver v2">V2</span>
                          </div>
                          <div className="k-v-value">
                            ${formatAmount(totalValueLocked?.["totalV2"], USD_DECIMALS, 0, true)}
                          </div>
                        </div>
                        <div className="chains">
                          {/* <div className="chain">
                            <img className="chain-img" src={fantomImg} alt="chain" />
                            <div className="chain-value">${formatAmount(tvl, 18, 0, true)}</div>
                          </div> */}
                          {STATS_CHAINS.map((chain) => {
                            if (!v2?.[chain.chainId]) return null;
                            return (
                              <div key={chain.chainId} className="chain">
                                <img className="chain-img" src={chain.img} alt={chain.chainId} />
                                <div className="chain-value">
                                  {" "}
                                  ${formatAmount(totalValueLocked.v2?.[chain.chainId], USD_DECIMALS, 0, true)}
                                  {/* $
                                  {formatAmount(
                                    parseValue(v2?.[chain.chainId]["volume"], USD_DECIMALS),
                                    USD_DECIMALS,
                                    0,
                                    true
                                  )} */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <div className="divider" />
                      <div className="k-v-box">
                        <div className="k-v-label">Total value locked on Fantom Legacy</div>
                        <div className="k-v-value">
                          ${formatAmount(totalValueLocked?.[FANTOM_LEGACY], USD_DECIMALS, 0, true)}
                        </div>
                      </div> */}
                    </CustomContent>
                  )}
                />
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={isLightTheme ? stats2Light : stats2} alt="img" />
          </div>
          <div className="stats-info">
            <div className="label">Total Volume</div>
            <div className="stats-value">
              {totalTradingVolume?.[chainId] ? (
                <TooltipComponent
                  position="left-bottom"
                  handle={
                    <CountUp
                      end={
                        chainId === FANTOM
                          ? parseFloat(
                              formatAmount(
                                BigNumber.from(totalTradingVolume?.[chainId]).add(
                                  parseValue(v2?.[chainId]?.["volume"] || 0, USD_DECIMALS)
                                ),
                                USD_DECIMALS,
                                0
                              )
                            )
                          : parseFloat(
                              formatAmount(
                                BigNumber.from(totalTradingVolume?.[chainId]).add(
                                  parseValue(v2?.[chainId]?.["volume"] || 0, USD_DECIMALS)
                                ),
                                USD_DECIMALS,
                                0
                              )
                            )
                      }
                      separator=","
                      prefix="$"
                    />
                  }
                  // renderContent={() => (
                  //   <StatsTooltip
                  //     title={`Total Volume`}
                  //     fantomValue={totalTradingVolume?.[FANTOM]}
                  //     fantomLegacyValue={totalTradingVolume?.[FANTOM_LEGACY]}
                  //     opValue={totalTradingVolume?.[OP]}
                  //     arbValue={totalTradingVolume?.[ARBITRUM]}
                  //     baseValue={totalTradingVolume?.[BASE]}
                  //     total={totalTradingVolume?.["total"]}
                  //   />
                  // )}
                  className="excep"
                  renderContent={() => (
                    <CustomContent>
                      <div className="k-v-box">
                        <div className="k-v-label">Total volume</div>
                        <div className="k-v-value">
                          ${formatAmount(totalAllVersion?.totalTradingVolume, USD_DECIMALS, 0, true)}
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="tooltip-row">
                        <div className="k-v-box">
                          <div className="k-v-label">
                            Total volume <span className="ver">V1</span>
                          </div>
                          <div className="k-v-value">
                            ${formatAmount(totalTradingVolume?.["total"], USD_DECIMALS, 0, true)}
                          </div>
                        </div>
                        <div className="chains">
                          {STATS_CHAINS.map((chain) => {
                            return (
                              <div key={chain.chainId} className="chain">
                                <img className="chain-img" src={chain.img} alt={chain.chainId} />
                                <div className="chain-value">
                                  ${formatAmount(totalTradingVolume?.[chain.chainId], USD_DECIMALS, 0, true)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="tooltip-row">
                        <div className="k-v-box">
                          <div className="k-v-label">
                            Total volume <span className="ver v2">V2</span>
                          </div>
                          <div className="k-v-value">
                            ${formatAmount(parseValue(v2?.["volume"], USD_DECIMALS), USD_DECIMALS, 0, true)}
                          </div>
                        </div>
                        <div className="chains">
                          {STATS_CHAINS.map((chain) => {
                            if (!v2?.[chain.chainId]) return null;
                            return (
                              <div key={chain.chainId} className="chain">
                                <img className="chain-img" src={chain.img} alt={chain.chainId} />
                                <div className="chain-value">
                                  $
                                  {formatAmount(
                                    parseValue(v2?.[chain.chainId]["volume"], USD_DECIMALS),
                                    USD_DECIMALS,
                                    0,
                                    true
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <div className="divider" />
                      <div className="k-v-box">
                        <div className="k-v-label">Total volume on Fantom Legacy</div>
                        <div className="k-v-value">
                          ${formatAmount(totalTradingVolume?.[FANTOM_LEGACY], USD_DECIMALS, 0, true)}
                        </div>
                      </div> */}
                    </CustomContent>
                  )}
                />
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={isLightTheme ? stats3Light : stats3} alt="img" />
          </div>
          <div className="stats-info">
            <div className="label">Total Fees</div>
            <div className="stats-value">
              {totalFees[chainId] ? (
                <TooltipComponent
                  position={isTablet ? "right-bottom" : "left-bottom"}
                  handle={
                    <CountUp
                      separator=","
                      prefix="$"
                      end={
                        chainId === FANTOM
                          ? parseFloat(
                              formatAmount(
                                BigNumber.from(totalFees[chainId]).add(
                                  parseValue(v2?.[chainId]?.["fees"] || 0, USD_DECIMALS)
                                ),
                                USD_DECIMALS,
                                0
                              )
                            )
                          : parseFloat(
                              formatAmount(
                                BigNumber.from(totalFees[chainId]).add(
                                  parseValue(v2?.[chainId]?.["fees"] || 0, USD_DECIMALS)
                                ),
                                USD_DECIMALS,
                                0
                              )
                            )
                      }
                    />
                  }
                  // renderContent={() => (
                  //   <StatsTooltip
                  //     title={`Total Fees`}
                  //     fantomValue={totalFees[FANTOM]}
                  //     fantomLegacyValue={totalFees[FANTOM_LEGACY]}
                  //     opValue={totalFees[OP]}
                  //     arbValue={totalFees[ARBITRUM]}
                  //     baseValue={totalFees[BASE]}
                  //     total={totalFees["total"]}
                  //   />
                  // )}
                  className="excep"
                  renderContent={() => (
                    <CustomContent>
                      <div className="k-v-box">
                        <div className="k-v-label">Total fees</div>
                        <div className="k-v-value">
                          {/* ${formatAmount(totalFees["total"], USD_DECIMALS, 0, true)} */}$
                          {formatAmount(totalAllVersion?.totalFees, USD_DECIMALS, 0, true)}
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="tooltip-row">
                        <div className="k-v-box">
                          <div className="k-v-label">
                            Total fees <span className="ver">V1</span>
                          </div>
                          <div className="k-v-value">${formatAmount(totalFees["total"], USD_DECIMALS, 0, true)}</div>
                        </div>
                        <div className="chains">
                          {STATS_CHAINS.map((chain) => {
                            return (
                              <div key={chain.chainId} className="chain">
                                <img className="chain-img" src={chain.img} alt={chain.chainId} />
                                <div className="chain-value">
                                  ${formatAmount(totalFees[chain.chainId], USD_DECIMALS, 0, true)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="tooltip-row">
                        <div className="k-v-box">
                          <div className="k-v-label">
                            Total fees <span className="ver v2">V2</span>
                          </div>
                          <div className="k-v-value">
                            ${formatAmount(parseValue(v2?.["fees"], USD_DECIMALS), USD_DECIMALS, 0, true)}
                          </div>
                        </div>
                        <div className="chains">
                          {STATS_CHAINS.map((chain) => {
                            if (!v2?.[chain.chainId]) return null;
                            return (
                              <div key={chain.chainId} className="chain">
                                <img className="chain-img" src={chain.img} alt={chain.chainId} />
                                <div className="chain-value">
                                  $
                                  {formatAmount(
                                    parseValue(v2?.[chain.chainId]["fees"], USD_DECIMALS),
                                    USD_DECIMALS,
                                    0,
                                    true
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <div className="divider" />
                      <div className="k-v-box">
                        <div className="k-v-label">Total fees on Fantom Legacy</div>
                        <div className="k-v-value">
                          ${formatAmount(totalFees[FANTOM_LEGACY], USD_DECIMALS, 0, true)}
                        </div>
                      </div> */}
                    </CustomContent>
                  )}
                />
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={isLightTheme ? longPositionLight : longPosition} alt="img" />
          </div>
          <div className="stats-info">
            <div className="label">Long Positions</div>
            <div className="stats-value">
              {longOpenInterest[chainId] ? (
                <TooltipComponent
                  position="left-bottom"
                  // className="nowrap"
                  handle={
                    <CountUp
                      // end={parseFloat(formatAmount(longOpenInterest[chainId], USD_DECIMALS, 0))}
                      end={
                        chainId === FANTOM
                          ? parseFloat(
                              formatAmount(
                                BigNumber.from(longOpenInterest[chainId]).add(
                                  parseValue(v2?.[chainId]?.["longAmountInterest"] || 0, USD_DECIMALS)
                                ),
                                USD_DECIMALS,
                                0
                              )
                            )
                          : parseFloat(
                              formatAmount(
                                BigNumber.from(longOpenInterest[chainId]).add(
                                  parseValue(v2?.[chainId]?.["longAmountInterest"] || 0, USD_DECIMALS)
                                ),
                                USD_DECIMALS,
                                0
                              )
                            )
                      }
                      separator=","
                      prefix="$"
                    />
                  }
                  // renderContent={() => (
                  //   <StatsTooltip
                  //     title={`Long Positions`}
                  //     fantomValue={longOpenInterest[FANTOM]}
                  //     fantomLegacyValue={longOpenInterest[FANTOM_LEGACY]}
                  //     arbValue={longOpenInterest[ARBITRUM]}
                  //     baseValue={longOpenInterest[BASE]}
                  //     opValue={longOpenInterest[OP]}
                  //     total={longOpenInterest["total"]}
                  //   />
                  // )}
                  className="excep"
                  renderContent={() => (
                    <CustomContent>
                      <div className="k-v-box">
                        <div className="k-v-label">Total long positions</div>
                        <div className="k-v-value">
                          ${formatAmount(totalAllVersion?.longOpenInterest, USD_DECIMALS, 0, true)}
                          {/* {chainId === FANTOM
                            ? formatAmount(
                                totalAllVersion?.longOpenInterest.add(longOpenInterest?.[FANTOM_LEGACY]),
                                USD_DECIMALS,
                                0,
                                true
                              )
                            : formatAmount(totalAllVersion?.longOpenInterest, USD_DECIMALS, 0, true)} */}
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="tooltip-row">
                        <div className="k-v-box">
                          <div className="k-v-label">
                            Total long positions <span className="ver">V1</span>
                          </div>
                          <div className="k-v-value">
                            ${formatAmount(longOpenInterest["total"], USD_DECIMALS, 0, true)}
                          </div>
                        </div>
                        <div className="chains">
                          {STATS_CHAINS.map((chain) => {
                            return (
                              <div key={chain.chainId} className="chain">
                                <img className="chain-img" src={chain.img} alt={chain.chainId} />
                                <div className="chain-value">
                                  ${formatAmount(longOpenInterest[chain.chainId], USD_DECIMALS, 0, true)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="tooltip-row">
                        <div className="k-v-box">
                          <div className="k-v-label">
                            Total long positions <span className="ver v2">V2</span>
                          </div>
                          <div className="k-v-value">
                            ${formatAmount(parseValue(v2?.["longAmountInterest"], USD_DECIMALS), USD_DECIMALS, 0, true)}
                          </div>
                        </div>
                        <div className="chains">
                          {STATS_CHAINS.map((chain) => {
                            if (!v2?.[chain.chainId]) return null;
                            return (
                              <div key={chain.chainId} className="chain">
                                <img className="chain-img" src={chain.img} alt={chain.chainId} />
                                <div className="chain-value">
                                  $
                                  {formatAmount(
                                    parseValue(v2?.[chain.chainId]["longAmountInterest"], USD_DECIMALS),
                                    USD_DECIMALS,
                                    0,
                                    true
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <div className="divider" />
                      <div className="k-v-box">
                        <div className="k-v-label">Total long positions on Fantom Legacy</div>
                        <div className="k-v-value">
                          ${formatAmount(longOpenInterest[FANTOM_LEGACY], USD_DECIMALS, 0, true)}
                        </div>
                      </div> */}
                    </CustomContent>
                  )}
                />
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={isLightTheme ? shortPositionLight : shortPosition} alt="img" />
          </div>
          <div className="stats-info">
            <div className="label">Short Positions</div>
            <div className="stats-value">
              {shortOpenInterest[chainId] ? (
                <TooltipComponent
                  position="left-bottom"
                  // className="nowrap"
                  handle={
                    <CountUp
                      // end={parseFloat(formatAmount(shortOpenInterest[chainId], USD_DECIMALS, 0))}
                      end={
                        chainId === FANTOM
                          ? parseFloat(
                              formatAmount(
                                BigNumber.from(shortOpenInterest[chainId]).add(
                                  parseValue(v2?.[chainId]?.["shortAmountInterest"] || 0, USD_DECIMALS)
                                ),
                                USD_DECIMALS,
                                0
                              )
                            )
                          : parseFloat(
                              formatAmount(
                                BigNumber.from(shortOpenInterest[chainId]).add(
                                  parseValue(v2?.[chainId]?.["shortAmountInterest"] || 0, USD_DECIMALS)
                                ),
                                USD_DECIMALS,
                                0
                              )
                            )
                      }
                      separator=","
                      prefix="$"
                    />
                  }
                  // renderContent={() => (
                  //   <StatsTooltip
                  //     title={`Short Positions`}
                  //     fantomValue={shortOpenInterest[FANTOM]}
                  //     fantomLegacyValue={shortOpenInterest[FANTOM_LEGACY]}
                  //     opValue={shortOpenInterest[OP]}
                  //     arbValue={shortOpenInterest[ARBITRUM]}
                  //     baseValue={shortOpenInterest[BASE]}
                  //     total={shortOpenInterest["total"]}
                  //   />
                  // )}
                  className="excep"
                  renderContent={() => (
                    <CustomContent>
                      <div className="k-v-box">
                        <div className="k-v-label">Total short positions</div>
                        <div className="k-v-value">
                          ${formatAmount(totalAllVersion?.shortOpenInterest, USD_DECIMALS, 0, true)}
                          {/* {chainId === FANTOM
                            ? formatAmount(
                                totalAllVersion?.shortOpenInterest.add(shortOpenInterest?.[FANTOM_LEGACY]),
                                USD_DECIMALS,
                                0,
                                true
                              )
                            : formatAmount(totalAllVersion?.shortOpenInterest, USD_DECIMALS, 0, true)} */}
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="tooltip-row">
                        <div className="k-v-box">
                          <div className="k-v-label">
                            Total short positions <span className="ver">V1</span>
                          </div>
                          <div className="k-v-value">
                            ${formatAmount(shortOpenInterest["total"], USD_DECIMALS, 0, true)}
                          </div>
                        </div>
                        <div className="chains">
                          {STATS_CHAINS.map((chain) => {
                            return (
                              <div key={chain.chainId} className="chain">
                                <img className="chain-img" src={chain.img} alt={chain.chainId} />
                                <div className="chain-value">
                                  ${formatAmount(shortOpenInterest[chain.chainId], USD_DECIMALS, 0, true)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="tooltip-row">
                        <div className="k-v-box">
                          <div className="k-v-label">
                            Total short positions <span className="ver v2">V2</span>
                          </div>
                          <div className="k-v-value">
                            $
                            {formatAmount(parseValue(v2?.["shortAmountInterest"], USD_DECIMALS), USD_DECIMALS, 0, true)}
                          </div>
                        </div>
                        <div className="chains">
                          {STATS_CHAINS.map((chain) => {
                            if (!v2?.[chain.chainId]) return null;
                            return (
                              <div key={chain.chainId} className="chain">
                                <img className="chain-img" src={chain.img} alt={chain.chainId} />
                                <div className="chain-value">
                                  $
                                  {formatAmount(
                                    parseValue(v2?.[chain.chainId]["shortAmountInterest"], USD_DECIMALS),
                                    USD_DECIMALS,
                                    0,
                                    true
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <div className="divider" />
                      <div className="k-v-box">
                        <div className="k-v-label">Total short positions on Fantom Legacy</div>
                        <div className="k-v-value">
                          ${formatAmount(shortOpenInterest[FANTOM_LEGACY], USD_DECIMALS, 0, true)}
                        </div>
                      </div> */}
                    </CustomContent>
                  )}
                />
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="stats-img">
            <img src={isLightTheme ? sinceLight : since} alt="img" />
          </div>
          {feeSinceToNow?.[chainId]?.timestamps && feeSinceToNow?.[chainId]?.value ? (
            <div className="stats-info">
              <div className="label">Fees since {formatDate(feeSinceToNow[chainId]?.timestamps)}</div>
              <div className="stats-value">
                <TooltipComponent
                  position={isTablet ? "right-bottom" : "left-bottom"}
                  // className="nowrap"
                  handle={
                    <CountUp
                      // end={parseFloat(formatAmount(feeSinceToNow?.[chainId]?.value, USD_DECIMALS, 2))}
                      end={
                        chainId === FANTOM
                          ? parseFloat(
                              formatAmount(
                                BigNumber.from(feeSinceToNow?.[chainId]?.value)?.add(
                                  parseValue(v2?.[chainId]?.["feeSince"] || 0, USD_DECIMALS)
                                ),
                                USD_DECIMALS,
                                0
                              )
                            )
                          : parseFloat(
                              formatAmount(
                                BigNumber.from(feeSinceToNow?.[chainId]?.value)?.add(
                                  parseValue(v2?.[chainId]?.["feeSince"] || 0, USD_DECIMALS)
                                ),
                                USD_DECIMALS,
                                0
                              )
                            )
                      }
                      separator=","
                      prefix="$"
                    />
                  }
                  // renderContent={() => (
                  //   <StatsTooltip
                  //     title={`Fees`}
                  //     fantomValue={feeSinceToNow[FANTOM]?.value}
                  //     fantomLegacyValue={feeSinceToNow[FANTOM_LEGACY].value}
                  //     opValue={feeSinceToNow[OP]?.value}
                  //     arbValue={feeSinceToNow[ARBITRUM]?.value}
                  //     baseValue={feeSinceToNow[BASE]?.value}
                  //     total={feeSinceToNow["total"]}
                  //   />
                  // )}
                  className="excep"
                  renderContent={() => (
                    <CustomContent>
                      <div className="k-v-box">
                        <div className="k-v-label">Fees since {formatDate(feeSinceToNow[chainId]?.timestamps)}</div>
                        <div className="k-v-value">
                          ${formatAmount(totalAllVersion?.totalFeeSince, USD_DECIMALS, 0, true)}
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="tooltip-row">
                        <div className="k-v-box">
                          <div className="k-v-label">
                            Fees <span className="ver">V1</span>
                          </div>
                          <div className="k-v-value">
                            ${formatAmount(feeSinceToNow["total"], USD_DECIMALS, 0, true)}
                          </div>
                        </div>
                        <div className="chains">
                          {STATS_CHAINS.map((chain) => {
                            return (
                              <div key={chain.chainId} className="chain">
                                <img className="chain-img" src={chain.img} alt={chain.chainId} />
                                <div className="chain-value">
                                  ${formatAmount(feeSinceToNow[chain.chainId]?.value, USD_DECIMALS, 0, true)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="tooltip-row">
                        <div className="k-v-box">
                          <div className="k-v-label">
                            Fees <span className="ver v2">V2</span>
                          </div>
                          <div className="k-v-value">
                            {parseValue(v2?.["feeSince"] || 0, USD_DECIMALS)?.lt(0) ? "-" : ""}$
                            {formatAmount(parseValue(v2?.["feeSince"], USD_DECIMALS), USD_DECIMALS, 0, true).replace(
                              "-",
                              ""
                            )}
                          </div>
                        </div>
                        <div className="chains">
                          {STATS_CHAINS.map((chain) => {
                            if (!v2?.[chain.chainId]) return null;
                            return (
                              <div key={chain.chainId} className="chain">
                                <img className="chain-img" src={chain.img} alt={chain.chainId} />
                                <div className="chain-value">
                                  {parseValue(v2?.[chain.chainId]?.["feeSince"] || 0, USD_DECIMALS).lt(0) ? "-" : ""}$
                                  {formatAmount(
                                    parseValue(v2?.[chain.chainId]["feeSince"], USD_DECIMALS),
                                    USD_DECIMALS,
                                    0,
                                    true
                                  ).replace("-", "")}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <div className="divider" />
                      <div className="k-v-box">
                        <div className="k-v-label">Fees on Fantom Legacy</div>
                        <div className="k-v-value">
                          ${formatAmount(feeSinceToNow[FANTOM_LEGACY]?.value, USD_DECIMALS, 0, true)}
                        </div>
                      </div> */}
                    </CustomContent>
                  )}
                />
              </div>
            </div>
          ) : (
            <div className="skeleton-box" style={{ width: "65px", height: "27px" }} />
          )}
        </StatBlock>
      </StatsContainer>
      <VolumeChart chainId={chainId} />
    </Wrapper>
  );
};

const ChainImg = styled.img`
  position: absolute;
  width: 202px;
  height: 202px;
  top: -36px;
  right: -40px;

  @media (max-width: 1023px) {
    width: 160px;
    height: 160px;
    right: -36px;
  }

  @media (max-width: 767px) {
    width: 64px;
    height: 64px;
    top: -16px;
    right: -16px;
  }
`;

const StatBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .stats-img {
    border: 1px solid transparent;
    background: linear-gradient(138.67deg, rgba(3, 245, 174, 0.15) 8.79%, rgba(3, 245, 174, 0) 100%);
    border-radius: 16px;
    padding: 12px;

    @media (max-width: 1023px) {
      width: 48px;
      height: 48px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .label {
    margin-bottom: 4px;
  }
  .stats-value {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;

    @media (max-width: 1023px) {
      font-size: 16px;
    }
  }
`;

const StatsContainer = styled.div`
  margin: 36px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  z-index: 3 !important;

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Description = styled.div`
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  a {
    color: #fff;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 4px;

  img {
    width: 32px;
    height: 32px;
  }
`;

const CustomContent = styled.div`
  width: 296px;

  .k-v-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--base-unit-xs-8, 8px);

    .k-v-label {
      color: var(--white-60, rgba(255, 255, 255, 0.6));
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */

      &:has(.ver) {
        display: flex;
        align-items: center;
        gap: var(--Border_radius, 4px);

        .ver {
          display: flex;
          padding: 0px var(--base-unit-xs-8, 8px);
          flex-direction: column;
          align-items: flex-start;
          gap: var(--base-unit-xs-8, 8px);
          border-radius: var(--Border_radius, 4px);
          border: 2px solid var(--main-primary, #03f5ae);
          height: 20px;

          color: var(--main-primary, #03f5ae);
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */

          &.v2 {
            color: var(--Warning, #ffdf76);
            border-radius: var(--Border_radius, 4px);
            border: 2px solid var(--Warning, #ffdf76);
          }
        }
      }
    }

    & > .k-v-value {
      color: #fff !important;
      font-size: 16px;
      font-weight: 700 !important;
      line-height: 140%; /* 22.4px */
    }
  }

  .divider {
    margin: 12px 0;
  }

  .tooltip-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }

  .chains {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--base-unit-xs-8, 8px);
  }

  .chain {
    display: flex;
    align-items: center;
    gap: var(--base-unit-xs-8, 8px);

    .chain-img {
      display: flex;
      width: 16px;
      height: 16px;
      justify-content: center;
      align-items: center;
    }

    .chain-value {
      color: var(--white-100, #fff);
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > *:not(.background-chain) {
    position: relative;
    z-index: 2;
  }

  .background-chain {
    background: radial-gradient(50% 285.34% at 100% 0%, rgba(0, 46, 88, 0.8) 0%, rgba(25, 27, 46, 0.8) 100%);
    position: absolute;
    border-radius: 24px;
    inset: 0;
    overflow: hidden;
    z-index: 1;

    &.op-content {
      background: radial-gradient(50% 285.34% at 100% 0%, rgba(88, 0, 0, 0.8) 0%, rgba(25, 27, 46, 0.8) 100%);
    }
  }

  @media screen and (max-width: 600px) {
    padding: 16px;
  }

  @media (max-width: 767px) {
    .Tooltip-popup {
      min-width: 240px !important;
      width: unset !important;
    }
  }

  &.theme--light {
    border-radius: 24px;
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

    .recharts-layer text {
      fill: #0d1a16b2 !important;
    }

    .background-chain {
      background: radial-gradient(50% 285.34% at 100% 0%, #a5e8ff 0%, #fff 100%);

      &.op-content {
        background: radial-gradient(50% 285.34% at 100% 0%, #ffa5af 0%, #fff 100%);
      }

      &.arb-content {
        background: radial-gradient(50% 285.34% at 100% 0%, #a5d3ff 0%, #fff 100%);
      }

      &.base-content {
        background: radial-gradient(50% 285.34% at 100% 0%, #aac5ff 0%, #fff 100%);
      }
    }

    ${Description} {
      * {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
      }
    }

    ${StatBlock} {
      .stats-img {
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
        background: linear-gradient(139deg, rgba(3, 245, 174, 0.35) 8.79%, rgba(3, 245, 174, 0) 100%);
      }
    }
  }
`;

export default Stats;
