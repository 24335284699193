import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useCallback, useEffect, useRef, useState } from "react";
import ReaderMsp from "abis/ReaderMsp.json";
import SettingsManager from "abis/SettingsManager.json";

import { getContract } from "config/contracts";
import { useChainId } from "lib/chains";

import { convertPositionData, DEFAULT_SLIPPAGE_AMOUNT } from "lib/legacy";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { SLIPPAGE_BPS_KEY } from "config/localStorage";
import { getProvider } from "lib/rpc";

const useUserTradeInfo = (account) => {
  const { chainId } = useChainId();
  const { library } = useWeb3React();

  const [listPosition, setListPosition] = useState([]);
  const [loading, setLoading] = useState(true);
  const readerAddress = getContract(chainId, "ReaderMsp");
  const settingsManagerAddress = getContract(chainId, "SettingsManager");

  var timeout = useRef(null);
  var oldAccount = useRef(null);
  const provider = getProvider(library, chainId);
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);

  useEffect(() => {
    const getTradeData = async () => {
      const readerContract = new ethers.Contract(readerAddress, ReaderMsp.abi, provider);
      const settingsManagerContract = new ethers.Contract(settingsManagerAddress, SettingsManager.abi, provider);
      if (readerContract && account) {
        const tradeData = await readerContract.getUserAlivePositions(account);

        const listLiquidateThreshold = await Promise.all(
          tradeData[1].map((element) => settingsManagerContract.liquidateThreshold(element.tokenId))
        );
        const list = convertPositionData(tradeData, listLiquidateThreshold, savedSlippageAmount);
        setListPosition(list);
        setLoading(false);
      }
    };
    if (account) {
      getTradeData();
      const clear = setInterval(() => {
        getTradeData().catch((err) => console.error(err.stack));
      }, 4000);

      return () => clearInterval(clear);
    } else {
      setLoading(false);
      setListPosition([]);
    }
  }, [account, chainId]);
  return { positions: listPosition, loading };
};

export default useUserTradeInfo;
