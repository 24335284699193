import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import { getContract } from "config/contracts";
import useSWR from "swr";

import OrderBookReader from "abis/OrderBookReader.json";
import OrderBook from "abis/OrderBook.json";

import { BASE, CHAIN_ID, FANTOM_TESTNET, getExplorerUrl, getRpcUrl } from "config/chains";
import { getServerBaseUrl } from "config/backend";
import { getMostAbundantStableToken } from "domain/tokens";
import { getTokenInfo } from "domain/tokens/utils";
import { getProvider } from "./rpc";
import { bigNumberify, expandDecimals, formatAmount, parseValue } from "./numbers";
import { getNormalizedTokenSymbol, getWrappedToken, isValidToken } from "config/tokens";
import { useChainId } from "./chains";
import { isValidTimestamp } from "./dates";
import { t } from "@lingui/macro";
import { ARBITRUM, AVALANCHE, FANTOM, FANTOM_LEGACY, OP, getChainName } from "config/chains";
import { SELECTED_NETWORK_LOCAL_STORAGE_KEY, THEME_KEY } from "config/localStorage";
import { isEmpty } from "lodash";

const { AddressZero } = ethers.constants;
export const ACTIVE_CHAIN_IDS = [FANTOM, FANTOM_LEGACY, OP];
export const ACTIVE_CHAIN_IDS_V2 = [FANTOM, OP];
export const ACTIVE_CHAIN_IDS_VMMY = [FANTOM, OP];

// use a random placeholder account instead of the zero address as the zero address might have tokens
export const PLACEHOLDER_ACCOUNT = ethers.Wallet.createRandom().address;

export const MIN_PROFIT_TIME = 0;

export const USDG_ADDRESS = getContract(CHAIN_ID, "USDG");
export const CHAIN_ID_LOCAL = localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) || CHAIN_ID;

export const BASIS_POINTS_DIVISOR = 10000;
// export const MAX_LEVERAGE = (chainIdFromLocalStorage == FANTOM ? 50 : 125) * BASIS_POINTS_DIVISOR;
// export const MAX_ALLOWED_LEVERAGE = (chainIdFromLocalStorage == FANTOM ? 30 : 100) * BASIS_POINTS_DIVISOR;
export function MAX_LEVERAGE(chainID) {
  switch (parseInt(chainID)) {
    case OP:
      return 125 * BASIS_POINTS_DIVISOR;
    default:
      return 125 * BASIS_POINTS_DIVISOR;
  }
}
export function MAX_ALLOWED_LEVERAGE(chainID) {
  switch (parseInt(chainID)) {
    case OP:
      return 100 * BASIS_POINTS_DIVISOR;
    default:
      return 100 * BASIS_POINTS_DIVISOR;
  }
}
// export function LEVERAGE_MARKS(chainID) {
//   switch (parseInt(chainID)) {
//     case OP:
//       return {
//         2: "2x",
//         20: "20x",
//         40: "40x",
//         60: "60x",
//         80: "80x",
//         100: "100x",
//       };
//     default:
//       return {
//         2: "2x",
//         20: "20x",
//         40: "40x",
//         60: "60x",
//         80: "80x",
//         100: "100x",
//       };
//   }
// }

export const THEME_DARK = "theme--dark";
export const THEME_LIGHT = "theme--light";
export const MAX_PRICE_DEVIATION_BASIS_POINTS = 750;
export const DEFAULT_GAS_LIMIT = 1 * 1000 * 1000;
export const SECONDS_PER_YEAR = 31536000;
export const SECONDS_PER_DAY = 24 * 60 * 60;
export const WEEK_PER_YEAR = 365 / 7;
export const USDG_DECIMALS = 18;
export const USD_DECIMALS = 30;
export const DEPOSIT_FEE = 30;
export const DUST_BNB = "2000000000000000";
export const DUST_USD = expandDecimals(1, USD_DECIMALS);
export const PRECISION = expandDecimals(1, 30);
export const GLP_DECIMALS = 18;
export const GMX_DECIMALS = 18;
export const NAV_DECIMALS = 18;
export const ESNAV_DECIMALS = 18;
export const NLP_DECIMALS = 18;
export const DEFAULT_MAX_USDG_AMOUNT = expandDecimals(200 * 1000 * 1000, 18);

export const TAX_BASIS_POINTS = 50;
export const STABLE_TAX_BASIS_POINTS = 5;
export const MINT_BURN_FEE_BASIS_POINTS = 20;
export const SWAP_FEE_BASIS_POINTS = 20;
export const STABLE_SWAP_FEE_BASIS_POINTS = 1;
export const MARGIN_FEE_BASIS_POINTS = 10;
export const MARGIN_FEE_BASIS_POINTS_FANTOM_V1 = 15;

export const LIQUIDATION_FEE = expandDecimals(5, USD_DECIMALS); //expandDecimals(5, USD_DECIMALS); //TODO

export const TRADES_PAGE_SIZE = 100;

export const GLP_COOLDOWN_DURATION = 120 * 60; //TODO
export const THRESHOLD_REDEMPTION_VALUE = expandDecimals(993, 27); // 0.993
export const FUNDING_RATE_PRECISION = 1000000;
export const LIQUIDATE_THRESHOLD_DIVISOR = 1000000;

export const SWAP = "Swap";
export const INCREASE = "Increase";
export const DECREASE = "Decrease";
export const LONG = "Long";
export const SHORT = "Short";

export const MARKET = "Market";
export const LIMIT = "Limit";
export const STOP = "Stop";
export const STOP_MARKET = "Stop Market";
export const STOP_LIMIT = "Stop Limit";
export const STOP_LOSS = "Stop Loss";
export const TAKE_PROFIT = "Take Profit";
export const LEVERAGE_ORDER_OPTIONS = [MARKET, LIMIT, STOP];
export const SWAP_ORDER_OPTIONS = [MARKET, LIMIT];
export const SWAP_OPTIONS = [LONG, SHORT];
export const SWAP_OPTIONS_SWAP = [SWAP];
export const SWAP_OPTIONS_V2 = [LONG, SHORT];
export const LEVERAGE_ORDER_OPTIONS_V2 = [MARKET, LIMIT, STOP_MARKET, STOP_LIMIT];
export const ORDER_TYPES = { Market: 0, Limit: 1, "Stop Market": 2, "Stop Limit": 3 };
export const POSITION_TYPES = ["Market Order", "Limit Order", "Stop Market", "Stop Limit", "Trailing Stop"];
export const DEFAULT_SLIPPAGE_AMOUNT = 30;
export const DEFAULT_HIGHER_SLIPPAGE_AMOUNT = 100;

export const OPEN_POSITION = "Open Position";
export const CLOSED_POSITION = "Closed Position";
export const OPEN_ORDER = "Open Order";
export const TRADE_ACTIVITY = "Trade Activity";
export const DEPOSITS = "Deposits";
export const WITHDRAWS = "Withdraws";
export const CASH_CASHOUT = "Cash & Cashout";

export const MSP_DECIMALS = 18;

export const REFERRAL_CODE_QUERY_PARAM = "ref";
export const MAX_REFERRAL_CODE_LENGTH = 20;
const HIGH_SPREAD_THRESHOLD = expandDecimals(1, USD_DECIMALS).div(100); // 1%;
export const MIN_PROFIT_BIPS = 0;
export const LEVERAGE_MARKS = (chainID, max = 100) => {
  if (max === 100)
    switch (parseInt(chainID)) {
      case OP:
        return {
          2: "2x",
          20: "20x",
          40: "40x",
          60: "60x",
          80: "80x",
          100: "100x",
        };
      default:
        return {
          2: "2x",
          20: "20x",
          40: "40x",
          60: "60x",
          80: "80x",
          100: "100x",
        };
    }
  else if (max <= 30) {
    return {
      2: "2x",
      5: "5x",
      10: "10x",
      15: "15x",
      20: "20x",
      25: "25x",
      30: "30x",
    };
  } else
    return {
      2: "2x",
      50: "50x",
      100: "100x",
      150: "150x",
      200: "200x",
      250: "250x",
    };
};
export function deserialize(data) {
  for (const [key, value] of Object.entries(data) as any) {
    if (value._type === "BigNumber") {
      data[key] = bigNumberify(value.value);
    }
  }
  return data;
}

export function isHomeSite() {
  // if(window.location.host?.includes("localhost"))
  return false;
  return !window.location.host?.includes("app.mummy.finance"); // process.env.REACT_APP_IS_HOME_SITE === "true"; //TODE
}

export function getLiquidationPriceFromDelta({ liquidationAmount, size, collateral, averagePrice, isLong }) {
  if (!size || size.eq(0)) {
    return;
  }

  if (liquidationAmount.gt(collateral)) {
    const liquidationDelta = liquidationAmount.sub(collateral);
    const priceDelta = liquidationDelta.mul(averagePrice).div(size);
    return isLong ? averagePrice.add(priceDelta) : averagePrice.sub(priceDelta);
  }

  const liquidationDelta = collateral.sub(liquidationAmount);
  const priceDelta = liquidationDelta.mul(averagePrice).div(size);

  return isLong ? averagePrice.sub(priceDelta) : averagePrice.add(priceDelta);
}

export function getMarginFee(sizeDelta) {
  if (!sizeDelta) {
    return bigNumberify(0);
  }
  const afterFeeUsd = sizeDelta.mul(BASIS_POINTS_DIVISOR - MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
  return sizeDelta.sub(afterFeeUsd);
}
export function getMarginFeeV1(chainId = FANTOM, sizeDelta) {
  if (!sizeDelta) {
    return bigNumberify(0);
  }
  const afterFeeUsd = sizeDelta
    .mul(BASIS_POINTS_DIVISOR - (chainId === FANTOM ? MARGIN_FEE_BASIS_POINTS_FANTOM_V1 : MARGIN_FEE_BASIS_POINTS))
    .div(BASIS_POINTS_DIVISOR);
  return sizeDelta.sub(afterFeeUsd);
}

export function isTriggerRatioInverted(fromTokenInfo, toTokenInfo) {
  if (!toTokenInfo || !fromTokenInfo) return false;
  if (toTokenInfo.isStable || toTokenInfo.isUsdg) return true;
  if (toTokenInfo.maxPrice) return toTokenInfo.maxPrice.lt(fromTokenInfo.maxPrice);
  return false;
}

export function getExchangeRate(tokenAInfo, tokenBInfo, inverted) {
  if (!tokenAInfo || !tokenAInfo.minPrice || !tokenBInfo || !tokenBInfo.maxPrice) {
    return;
  }
  if (inverted) {
    return tokenAInfo.minPrice.mul(PRECISION).div(tokenBInfo.maxPrice);
  }
  return tokenBInfo.maxPrice.mul(PRECISION).div(tokenAInfo.minPrice);
}

export function shouldInvertTriggerRatio(tokenA, tokenB) {
  if (tokenB.isStable || tokenB.isUsdg) return true;
  if (tokenB.maxPrice && tokenA.maxPrice && tokenB.maxPrice.lt(tokenA.maxPrice)) return true;
  return false;
}

export function getExchangeRateDisplay(rate, tokenA, tokenB, opts: { omitSymbols?: boolean } = {}) {
  if (!rate || !tokenA || !tokenB) return "...";
  if (shouldInvertTriggerRatio(tokenA, tokenB)) {
    [tokenA, tokenB] = [tokenB, tokenA];
    rate = PRECISION.mul(PRECISION).div(rate);
  }
  const rateValue = formatAmount(rate, USD_DECIMALS, tokenA.isStable || tokenA.isUsdg ? 2 : 4, true);
  if (opts.omitSymbols) {
    return rateValue;
  }
  return `${rateValue} ${tokenA.symbol} / ${tokenB.symbol}`;
}

export function getExchangeRateNormalizeDisplay(rate, tokenA, tokenB, opts: { omitSymbols?: boolean } = {}) {
  if (!rate || !tokenA || !tokenB) return "...";
  if (shouldInvertTriggerRatio(tokenA, tokenB)) {
    [tokenA, tokenB] = [tokenB, tokenA];
    rate = PRECISION.mul(PRECISION).div(rate);
  }
  const rateValue = formatAmount(rate, USD_DECIMALS, tokenA.isStable || tokenA.isUsdg ? 2 : 4, true);
  if (opts.omitSymbols) {
    return rateValue;
  }
  return `${rateValue} ${getNormalizedTokenSymbol(tokenA.symbol)} / ${getNormalizedTokenSymbol(tokenB.symbol)}`;
}

export const adjustForDecimalsFactory = (n) => (number) => {
  if (n === 0) {
    return number;
  }
  if (n > 0) {
    return number.mul(expandDecimals(1, n));
  }
  return number.div(expandDecimals(1, -n));
};

export function adjustForDecimals(amount, divDecimals, mulDecimals) {
  return amount.mul(expandDecimals(1, mulDecimals)).div(expandDecimals(1, divDecimals));
}

export function getTargetUsdgAmount(token, usdgSupply, totalTokenWeights) {
  if (!token || !token.weight || !usdgSupply) {
    return;
  }

  if (usdgSupply.eq(0)) {
    return bigNumberify(0);
  }

  return token.weight.mul(usdgSupply).div(totalTokenWeights);
}

export function getFeeBasisPoints(
  token,
  usdgDelta,
  feeBasisPoints,
  taxBasisPoints,
  increment,
  usdgSupply,
  totalTokenWeights
) {
  if (!token || !token.usdgAmount || !usdgSupply || !totalTokenWeights) {
    return 0;
  }

  feeBasisPoints = bigNumberify(feeBasisPoints);
  taxBasisPoints = bigNumberify(taxBasisPoints);

  const initialAmount = token.usdgAmount;
  let nextAmount = initialAmount.add(usdgDelta);
  if (!increment) {
    nextAmount = usdgDelta.gt(initialAmount) ? bigNumberify(0) : initialAmount.sub(usdgDelta);
  }

  const targetAmount = getTargetUsdgAmount(token, usdgSupply, totalTokenWeights);
  if (!targetAmount || targetAmount.eq(0)) {
    return feeBasisPoints.toNumber();
  }

  const initialDiff = initialAmount.gt(targetAmount)
    ? initialAmount.sub(targetAmount)
    : targetAmount.sub(initialAmount);
  const nextDiff = nextAmount.gt(targetAmount) ? nextAmount.sub(targetAmount) : targetAmount.sub(nextAmount);

  if (nextDiff.lt(initialDiff)) {
    const rebateBps = taxBasisPoints.mul(initialDiff).div(targetAmount);
    return rebateBps.gt(feeBasisPoints) ? 0 : feeBasisPoints.sub(rebateBps).toNumber();
  }

  let averageDiff = initialDiff.add(nextDiff).div(2);
  if (averageDiff.gt(targetAmount)) {
    averageDiff = targetAmount;
  }
  const taxBps = taxBasisPoints.mul(averageDiff).div(targetAmount);
  return feeBasisPoints.add(taxBps).toNumber();
}

export function getBuyGlpToAmount(fromAmount, swapTokenAddress, infoTokens, glpPrice, usdgSupply, totalTokenWeights) {
  const defaultValue = { amount: bigNumberify(0), feeBasisPoints: 0 };
  if (!fromAmount || !swapTokenAddress || !infoTokens || !glpPrice || !usdgSupply || !totalTokenWeights) {
    return defaultValue;
  }

  const swapToken = getTokenInfo(infoTokens, swapTokenAddress);
  if (!swapToken || !swapToken.minPrice) {
    return defaultValue;
  }

  let glpAmount = fromAmount.mul(swapToken.minPrice).div(glpPrice);
  glpAmount = adjustForDecimals(glpAmount, swapToken.decimals, USDG_DECIMALS);

  let usdgAmount = fromAmount.mul(swapToken.minPrice).div(PRECISION);
  usdgAmount = adjustForDecimals(usdgAmount, swapToken.decimals, USDG_DECIMALS);
  const feeBasisPoints = getFeeBasisPoints(
    swapToken,
    usdgAmount,
    MINT_BURN_FEE_BASIS_POINTS,
    TAX_BASIS_POINTS,
    true,
    usdgSupply,
    totalTokenWeights
  );

  glpAmount = glpAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR);

  return { amount: glpAmount, feeBasisPoints };
}

export function getSellGlpFromAmount(toAmount, swapTokenAddress, infoTokens, glpPrice, usdgSupply, totalTokenWeights) {
  const defaultValue = { amount: bigNumberify(0), feeBasisPoints: 0 };
  if (!toAmount || !swapTokenAddress || !infoTokens || !glpPrice || !usdgSupply || !totalTokenWeights) {
    return defaultValue;
  }

  const swapToken = getTokenInfo(infoTokens, swapTokenAddress);
  if (!swapToken || !swapToken.maxPrice) {
    return defaultValue;
  }

  let glpAmount = toAmount.mul(swapToken.maxPrice).div(glpPrice);
  glpAmount = adjustForDecimals(glpAmount, swapToken.decimals, USDG_DECIMALS);

  let usdgAmount = toAmount.mul(swapToken.maxPrice).div(PRECISION);
  usdgAmount = adjustForDecimals(usdgAmount, swapToken.decimals, USDG_DECIMALS);
  const feeBasisPoints = getFeeBasisPoints(
    swapToken,
    usdgAmount,
    MINT_BURN_FEE_BASIS_POINTS,
    TAX_BASIS_POINTS,
    false,
    usdgSupply,
    totalTokenWeights
  );

  glpAmount = glpAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints);

  return { amount: glpAmount, feeBasisPoints };
}

export function getBuyGlpFromAmount(toAmount, fromTokenAddress, infoTokens, glpPrice, usdgSupply, totalTokenWeights) {
  const defaultValue = { amount: bigNumberify(0) };
  if (!toAmount || !fromTokenAddress || !infoTokens || !glpPrice || !usdgSupply || !totalTokenWeights) {
    return defaultValue;
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
  if (!fromToken || !fromToken.minPrice) {
    return defaultValue;
  }

  let fromAmount = toAmount.mul(glpPrice).div(fromToken.minPrice);
  fromAmount = adjustForDecimals(fromAmount, GLP_DECIMALS, fromToken.decimals);

  const usdgAmount = toAmount.mul(glpPrice).div(PRECISION);
  const feeBasisPoints = getFeeBasisPoints(
    fromToken,
    usdgAmount,
    MINT_BURN_FEE_BASIS_POINTS,
    TAX_BASIS_POINTS,
    true,
    usdgSupply,
    totalTokenWeights
  );

  fromAmount = fromAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints);

  return { amount: fromAmount, feeBasisPoints };
}

export function getSellGlpToAmount(toAmount, fromTokenAddress, infoTokens, glpPrice, usdgSupply, totalTokenWeights) {
  const defaultValue = { amount: bigNumberify(0) };
  if (!toAmount || !fromTokenAddress || !infoTokens || !glpPrice || !usdgSupply || !totalTokenWeights) {
    return defaultValue;
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
  if (!fromToken || !fromToken.maxPrice) {
    return defaultValue;
  }

  let fromAmount = toAmount.mul(glpPrice).div(fromToken.maxPrice);
  fromAmount = adjustForDecimals(fromAmount, GLP_DECIMALS, fromToken.decimals);

  const usdgAmount = toAmount.mul(glpPrice).div(PRECISION);
  const feeBasisPoints = getFeeBasisPoints(
    fromToken,
    usdgAmount,
    MINT_BURN_FEE_BASIS_POINTS,
    TAX_BASIS_POINTS,
    false,
    usdgSupply,
    totalTokenWeights
  );

  fromAmount = fromAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR);

  return { amount: fromAmount, feeBasisPoints };
}

export function getNextFromAmount(
  chainId,
  toAmount,
  fromTokenAddress,
  toTokenAddress,
  infoTokens,
  toTokenPriceUsd,
  ratio,
  usdgSupply,
  totalTokenWeights,
  forSwap
) {
  const defaultValue = { amount: bigNumberify(0) };

  if (!toAmount || !fromTokenAddress || !toTokenAddress || !infoTokens) {
    return defaultValue;
  }

  if (fromTokenAddress === toTokenAddress) {
    return { amount: toAmount };
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
  const toToken = getTokenInfo(infoTokens, toTokenAddress);

  if (fromToken.isNative && toToken.isWrapped) {
    return { amount: toAmount };
  }

  if (fromToken.isWrapped && toToken.isNative) {
    return { amount: toAmount };
  }

  // the realtime price should be used if it is for a transaction to open / close a position
  // or if the transaction involves doing a swap and opening / closing a position
  // otherwise use the contract price instead of realtime price for swaps

  let fromTokenMinPrice;
  if (fromToken) {
    fromTokenMinPrice = forSwap ? fromToken.contractMinPrice : fromToken.minPrice;
  }

  let toTokenMaxPrice;
  if (toToken) {
    toTokenMaxPrice = forSwap ? toToken.contractMaxPrice : toToken.maxPrice;
  }

  if (!fromToken || !fromTokenMinPrice || !toToken || !toTokenMaxPrice) {
    return defaultValue;
  }

  const adjustDecimals = adjustForDecimalsFactory(fromToken.decimals - toToken.decimals);

  let fromAmountBasedOnRatio;
  if (ratio && !ratio.isZero()) {
    fromAmountBasedOnRatio = toAmount.mul(ratio).div(PRECISION);
  }

  const fromAmount =
    ratio && !ratio.isZero() ? fromAmountBasedOnRatio : toAmount.mul(toTokenMaxPrice).div(fromTokenMinPrice);

  let usdgAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION);
  usdgAmount = adjustForDecimals(usdgAmount, toToken.decimals, USDG_DECIMALS);
  const swapFeeBasisPoints =
    fromToken.isStable && toToken.isStable ? STABLE_SWAP_FEE_BASIS_POINTS : SWAP_FEE_BASIS_POINTS;
  const taxBasisPoints = fromToken.isStable && toToken.isStable ? STABLE_TAX_BASIS_POINTS : TAX_BASIS_POINTS;
  const feeBasisPoints0 = getFeeBasisPoints(
    fromToken,
    usdgAmount,
    swapFeeBasisPoints,
    taxBasisPoints,
    true,
    usdgSupply,
    totalTokenWeights
  );
  const feeBasisPoints1 = getFeeBasisPoints(
    toToken,
    usdgAmount,
    swapFeeBasisPoints,
    taxBasisPoints,
    false,
    usdgSupply,
    totalTokenWeights
  );
  const feeBasisPoints = feeBasisPoints0 > feeBasisPoints1 ? feeBasisPoints0 : feeBasisPoints1;

  return {
    amount: adjustDecimals(fromAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints)),
    feeBasisPoints,
  };
}

export function getNextToAmount(
  chainId,
  fromAmount,
  fromTokenAddress,
  toTokenAddress,
  infoTokens,
  toTokenPriceUsd,
  ratio,
  usdgSupply,
  totalTokenWeights,
  forSwap
) {
  const defaultValue = { amount: bigNumberify(0) };
  if (!fromAmount || !fromTokenAddress || !toTokenAddress || !infoTokens) {
    return defaultValue;
  }

  if (fromTokenAddress === toTokenAddress) {
    return { amount: fromAmount };
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
  const toToken = getTokenInfo(infoTokens, toTokenAddress);

  if (fromToken.isNative && toToken.isWrapped) {
    return { amount: fromAmount };
  }

  if (fromToken.isWrapped && toToken.isNative) {
    return { amount: fromAmount };
  }

  // the realtime price should be used if it is for a transaction to open / close a position
  // or if the transaction involves doing a swap and opening / closing a position
  // otherwise use the contract price instead of realtime price for swaps

  let fromTokenMinPrice;
  if (fromToken) {
    fromTokenMinPrice = forSwap ? fromToken.contractMinPrice : fromToken.minPrice;
  }

  let toTokenMaxPrice;
  if (toToken) {
    toTokenMaxPrice = forSwap ? toToken.contractMaxPrice : toToken.maxPrice;
  }

  if (!fromTokenMinPrice || !toTokenMaxPrice) {
    return defaultValue;
  }

  const adjustDecimals = adjustForDecimalsFactory(toToken.decimals - fromToken.decimals);

  let toAmountBasedOnRatio = bigNumberify(0)!;
  if (ratio && !ratio.isZero()) {
    toAmountBasedOnRatio = fromAmount.mul(PRECISION).div(ratio);
  }

  if (toTokenAddress === USDG_ADDRESS) {
    const feeBasisPoints = getSwapFeeBasisPoints(fromToken.isStable);

    if (ratio && !ratio.isZero()) {
      const toAmount = toAmountBasedOnRatio;
      return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
      };
    }

    const toAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION);
    return {
      amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
      feeBasisPoints,
    };
  }

  if (fromTokenAddress === USDG_ADDRESS) {
    const redemptionValue = toToken.redemptionAmount
      ?.mul(toTokenPriceUsd || toTokenMaxPrice)
      .div(expandDecimals(1, toToken.decimals));

    if (redemptionValue && redemptionValue.gt(THRESHOLD_REDEMPTION_VALUE)) {
      const feeBasisPoints = getSwapFeeBasisPoints(toToken.isStable);

      const toAmount =
        ratio && !ratio.isZero()
          ? toAmountBasedOnRatio
          : fromAmount.mul(toToken.redemptionAmount).div(expandDecimals(1, toToken.decimals));

      return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
      };
    }

    const expectedAmount = fromAmount;

    const stableToken = getMostAbundantStableToken(chainId, infoTokens);
    if (!stableToken?.availableAmount || stableToken.availableAmount.lt(expectedAmount)) {
      const toAmount =
        ratio && !ratio.isZero()
          ? toAmountBasedOnRatio
          : fromAmount.mul(toToken.redemptionAmount).div(expandDecimals(1, toToken.decimals));
      const feeBasisPoints = getSwapFeeBasisPoints(toToken.isStable);
      return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
      };
    }

    const feeBasisPoints0 = getSwapFeeBasisPoints(true);
    const feeBasisPoints1 = getSwapFeeBasisPoints(false);

    if (ratio && !ratio.isZero()) {
      const toAmount = toAmountBasedOnRatio
        .mul(BASIS_POINTS_DIVISOR - feeBasisPoints0 - feeBasisPoints1)
        .div(BASIS_POINTS_DIVISOR);
      return {
        amount: adjustDecimals(toAmount),
        path: [USDG_ADDRESS, stableToken.address, toToken.address],
        feeBasisPoints: feeBasisPoints0 + feeBasisPoints1,
      };
    }

    // get toAmount for USDG => stableToken
    let toAmount = fromAmount.mul(PRECISION).div(stableToken.maxPrice);
    // apply USDG => stableToken fees
    toAmount = toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints0).div(BASIS_POINTS_DIVISOR);

    // get toAmount for stableToken => toToken
    toAmount = toAmount.mul(stableToken.minPrice).div(toTokenPriceUsd || toTokenMaxPrice);
    // apply stableToken => toToken fees
    toAmount = toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints1).div(BASIS_POINTS_DIVISOR);

    return {
      amount: adjustDecimals(toAmount),
      path: [USDG_ADDRESS, stableToken.address, toToken.address],
      feeBasisPoints: feeBasisPoints0 + feeBasisPoints1,
    };
  }

  const toAmount =
    ratio && !ratio.isZero()
      ? toAmountBasedOnRatio
      : fromAmount.mul(fromTokenMinPrice).div(toTokenPriceUsd || toTokenMaxPrice);

  let usdgAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION);
  usdgAmount = adjustForDecimals(usdgAmount, fromToken.decimals, USDG_DECIMALS);
  const swapFeeBasisPoints =
    fromToken.isStable && toToken.isStable ? STABLE_SWAP_FEE_BASIS_POINTS : SWAP_FEE_BASIS_POINTS;
  const taxBasisPoints = fromToken.isStable && toToken.isStable ? STABLE_TAX_BASIS_POINTS : TAX_BASIS_POINTS;
  const feeBasisPoints0 = getFeeBasisPoints(
    fromToken,
    usdgAmount,
    swapFeeBasisPoints,
    taxBasisPoints,
    true,
    usdgSupply,
    totalTokenWeights
  );
  const feeBasisPoints1 = getFeeBasisPoints(
    toToken,
    usdgAmount,
    swapFeeBasisPoints,
    taxBasisPoints,
    false,
    usdgSupply,
    totalTokenWeights
  );
  const feeBasisPoints = feeBasisPoints0 > feeBasisPoints1 ? feeBasisPoints0 : feeBasisPoints1;

  return {
    amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
    feeBasisPoints,
  };
}

export function getProfitPrice(closePrice, position) {
  let profitPrice;
  if (position && position.averagePrice && closePrice) {
    profitPrice = position.isLong
      ? position.averagePrice.mul(BASIS_POINTS_DIVISOR + MIN_PROFIT_BIPS).div(BASIS_POINTS_DIVISOR)
      : position.averagePrice.mul(BASIS_POINTS_DIVISOR - MIN_PROFIT_BIPS).div(BASIS_POINTS_DIVISOR);
  }
  return profitPrice;
}

export function calculatePositionDelta(
  price,
  { size, collateral, isLong, averagePrice, lastIncreasedTime },
  sizeDelta
) {
  if (!sizeDelta) {
    sizeDelta = size;
  }
  const priceDelta = averagePrice.gt(price) ? averagePrice.sub(price) : price.sub(averagePrice);
  let delta = sizeDelta.mul(priceDelta).div(averagePrice);
  const pendingDelta = delta;

  const minProfitExpired = lastIncreasedTime + MIN_PROFIT_TIME < Date.now() / 1000;
  const hasProfit = isLong ? price.gt(averagePrice) : price.lt(averagePrice);
  if (!minProfitExpired && hasProfit && delta.mul(BASIS_POINTS_DIVISOR).lte(size.mul(MIN_PROFIT_BIPS))) {
    delta = bigNumberify(0);
  }

  const deltaPercentage = delta.mul(BASIS_POINTS_DIVISOR).div(collateral);
  const pendingDeltaPercentage = pendingDelta.mul(BASIS_POINTS_DIVISOR).div(collateral);

  return {
    delta,
    pendingDelta,
    pendingDeltaPercentage,
    hasProfit,
    deltaPercentage,
  };
}

export function getDeltaStr({ delta, deltaPercentage, hasProfit }) {
  let deltaStr;
  let deltaPercentageStr;

  if (delta.gt(0)) {
    deltaStr = hasProfit ? "+" : "-";
    deltaPercentageStr = hasProfit ? "+" : "-";
  } else {
    deltaStr = "";
    deltaPercentageStr = "";
  }
  deltaStr += `$${formatAmount(delta, USD_DECIMALS, 2, true)}`;
  deltaPercentageStr += `${formatAmount(deltaPercentage, 2, 2)}%`;

  return { deltaStr, deltaPercentageStr };
}

export function getLeverage({
  size,
  sizeDelta,
  increaseSize,
  collateral,
  collateralDelta,
  increaseCollateral,
  entryFundingRate,
  cumulativeFundingRate,
  hasProfit,
  delta,
  includeDelta,
}) {
  if (!size && !sizeDelta) {
    return;
  }
  if (!collateral && !collateralDelta) {
    return;
  }

  let nextSize = size ? size : bigNumberify(0);
  if (sizeDelta) {
    if (increaseSize) {
      nextSize = size.add(sizeDelta);
    } else {
      if (sizeDelta.gte(size)) {
        return;
      }
      nextSize = size.sub(sizeDelta);
    }
  }

  let remainingCollateral = collateral ? collateral : bigNumberify(0);
  if (collateralDelta) {
    if (increaseCollateral) {
      remainingCollateral = collateral.add(collateralDelta);
    } else {
      if (collateralDelta.gte(collateral)) {
        return;
      }
      remainingCollateral = collateral.sub(collateralDelta);
    }
  }

  if (delta && includeDelta) {
    if (hasProfit) {
      remainingCollateral = remainingCollateral.add(delta);
    } else {
      if (delta.gt(remainingCollateral)) {
        return;
      }

      remainingCollateral = remainingCollateral.sub(delta);
    }
  }

  if (remainingCollateral.eq(0)) {
    return;
  }

  remainingCollateral = sizeDelta
    ? remainingCollateral.mul(BASIS_POINTS_DIVISOR - MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR)
    : remainingCollateral;
  if (entryFundingRate && cumulativeFundingRate) {
    const fundingFee = size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
    remainingCollateral = remainingCollateral.sub(fundingFee);
  }

  return nextSize.mul(BASIS_POINTS_DIVISOR).div(remainingCollateral);
}

export function getLiquidationPrice(data) {
  let {
    isLong,
    size,
    collateral,
    averagePrice,
    entryFundingRate,
    cumulativeFundingRate,
    sizeDelta,
    collateralDelta,
    increaseCollateral,
    increaseSize,
    delta,
    hasProfit,
    includeDelta,
  } = data;
  if (!size || !collateral || !averagePrice) {
    return;
  }

  let nextSize = size ? size : bigNumberify(0);
  let remainingCollateral = collateral;

  if (sizeDelta) {
    if (increaseSize) {
      nextSize = size.add(sizeDelta);
    } else {
      if (sizeDelta.gte(size)) {
        return;
      }
      nextSize = size.sub(sizeDelta);
    }

    if (includeDelta && !hasProfit) {
      const adjustedDelta = sizeDelta.mul(delta).div(size);
      remainingCollateral = remainingCollateral.sub(adjustedDelta);
    }
  }

  if (collateralDelta) {
    if (increaseCollateral) {
      remainingCollateral = remainingCollateral.add(collateralDelta);
    } else {
      if (collateralDelta.gte(remainingCollateral)) {
        return;
      }
      remainingCollateral = remainingCollateral.sub(collateralDelta);
    }
  }

  let positionFee = getMarginFee(size).add(LIQUIDATION_FEE);
  if (entryFundingRate && cumulativeFundingRate) {
    const fundingFee = size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
    positionFee = positionFee.add(fundingFee);
  }

  const liquidationPriceForFees = getLiquidationPriceFromDelta({
    liquidationAmount: positionFee,
    size: nextSize,
    collateral: remainingCollateral,
    averagePrice,
    isLong,
  });

  const liquidationPriceForMaxLeverage = getLiquidationPriceFromDelta({
    liquidationAmount: nextSize.mul(BASIS_POINTS_DIVISOR).div(MAX_LEVERAGE(CHAIN_ID_LOCAL)),
    size: nextSize,
    collateral: remainingCollateral,
    averagePrice,
    isLong,
  });

  if (!liquidationPriceForFees) {
    return liquidationPriceForMaxLeverage;
  }
  if (!liquidationPriceForMaxLeverage) {
    return liquidationPriceForFees;
  }

  if (isLong) {
    // return the higher price
    return liquidationPriceForFees.gt(liquidationPriceForMaxLeverage)
      ? liquidationPriceForFees
      : liquidationPriceForMaxLeverage;
  }

  // return the lower price
  return liquidationPriceForFees.lt(liquidationPriceForMaxLeverage)
    ? liquidationPriceForFees
    : liquidationPriceForMaxLeverage;
}

export function getPositionKey(
  account: string,
  collateralTokenAddress: string,
  indexTokenAddress: string,
  isLong: boolean,
  nativeTokenAddress?: string
) {
  const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress;
  const tokenAddress1 = indexTokenAddress === AddressZero ? nativeTokenAddress : indexTokenAddress;
  return account + ":" + tokenAddress0 + ":" + tokenAddress1 + ":" + isLong;
}

export function getPositionContractKey(account, collateralToken, indexToken, isLong) {
  return ethers.utils.solidityKeccak256(
    ["address", "address", "address", "bool"],
    [account, collateralToken, indexToken, isLong]
  );
}

export function getSwapFeeBasisPoints(isStable) {
  return isStable ? STABLE_SWAP_FEE_BASIS_POINTS : SWAP_FEE_BASIS_POINTS;
}

export function shortenAddress(address, length) {
  if (!length) {
    return "";
  }
  if (!address) {
    return address;
  }
  if (address.length < 10) {
    return address;
  }
  let left = Math.floor((length - 3) / 2) + 1;
  return address.substring(0, left) + "..." + address.substring(address.length - (length - (left + 3)), address.length);
}

export function useENS(address) {
  const [ensName, setENSName] = useState<string | undefined>();

  useEffect(() => {
    async function resolveENS() {
      if (address) {
        const provider = new ethers.providers.JsonRpcProvider(getRpcUrl(FANTOM));
        const name = await provider.lookupAddress(address.toLowerCase());
        if (name) setENSName(name);
      }
    }
    resolveENS();
  }, [address]);

  return { ensName };
}

function _parseOrdersData(ordersData, account, indexes, extractor, uintPropsLength, addressPropsLength) {
  if (!ordersData || ordersData.length === 0) {
    return [];
  }
  const [uintProps, addressProps] = ordersData;
  const count = uintProps.length / uintPropsLength;

  const orders: any[] = [];
  for (let i = 0; i < count; i++) {
    const sliced = addressProps
      .slice(addressPropsLength * i, addressPropsLength * (i + 1))
      .concat(uintProps.slice(uintPropsLength * i, uintPropsLength * (i + 1)));

    if (sliced[0] === AddressZero && sliced[1] === AddressZero) {
      continue;
    }

    const order = extractor(sliced);
    order.index = indexes[i];
    order.account = account;
    orders.push(order);
  }

  return orders;
}

function parseDecreaseOrdersData(chainId, decreaseOrdersData, account, indexes) {
  const extractor = (sliced) => {
    const isLong = sliced[4].toString() === "1";
    return {
      collateralToken: sliced[0],
      indexToken: sliced[1],
      collateralDelta: sliced[2],
      sizeDelta: sliced[3],
      isLong,
      triggerPrice: sliced[5],
      triggerAboveThreshold: sliced[6].toString() === "1",
      type: DECREASE,
    };
  };
  return _parseOrdersData(decreaseOrdersData, account, indexes, extractor, 5, 2).filter((order) => {
    return isValidToken(chainId, order.collateralToken) && isValidToken(chainId, order.indexToken);
  });
}

function parseIncreaseOrdersData(chainId, increaseOrdersData, account, indexes) {
  const extractor = (sliced) => {
    const isLong = sliced[5].toString() === "1";
    return {
      purchaseToken: sliced[0],
      collateralToken: sliced[1],
      indexToken: sliced[2],
      purchaseTokenAmount: sliced[3],
      sizeDelta: sliced[4],
      isLong,
      triggerPrice: sliced[6],
      triggerAboveThreshold: sliced[7].toString() === "1",
      type: INCREASE,
    };
  };
  return _parseOrdersData(increaseOrdersData, account, indexes, extractor, 5, 3).filter((order) => {
    return (
      isValidToken(chainId, order.purchaseToken) &&
      isValidToken(chainId, order.collateralToken) &&
      isValidToken(chainId, order.indexToken)
    );
  });
}
export function getLiqPrice(liqThreshold, collateral, posSize, accruedFees, isLong, entryPrice) {
  let liqPrice;
  let deltaLiqPercent = liqThreshold
    .mul(collateral)
    .sub(accruedFees.mul(parseValue(1, 6)))
    .div(posSize);
  if (isLong) {
    liqPrice = entryPrice.mul(bigNumberify(1000000)?.sub(deltaLiqPercent)).div(1000000);
  } else {
    liqPrice = entryPrice.mul(bigNumberify(1000000)?.add(deltaLiqPercent)).div(1000000);
  }
  return liqPrice;
}

//liq risk = PnL after fees / (0.99 * collateral) * 100
export function getLiqRisk(uPnL, collateral) {
  if (uPnL.lt(0))
    return uPnL
      .mul(parseValue("100", 30))
      .mul(parseValue("1", 30))
      .div(collateral.mul(parseValue("0.99", 30)));
  return BigNumber.from(0);
}

export function getRealizedPnl() {}
export function getMarketTokensInfo(marketAssetsList, marketPrices) {
  const results: any[] = [];
  let count = 0;
  if (marketAssetsList && marketPrices) {
    for (const [key, value] of Object.entries(marketPrices)) {
      for (let i = 0; i < marketAssetsList.length; i++) {
        if (key === marketAssetsList[i].id) {
          results.push({
            ...marketPrices[key],
            ...marketAssetsList[i],
          });
          break;
        }
      }
    }
  }
  return results;
}
export function getUnrealizedPnl(accruedFees, markPrice, avgEntryPrice, isLong, posQty) {
  let uPnL = bigNumberify(0);
  if (isLong) {
    uPnL = accruedFees.add(markPrice.sub(avgEntryPrice)).mul(posQty);
  } else {
    uPnL = accruedFees.add(avgEntryPrice.sub(markPrice)).mul(posQty);
  }
  return uPnL;
}

export function getPnlWithoutFee(isLong = true, markPrice, averagePrice, size) {
  const pnlWithoutFee = isLong
    ? markPrice?.sub(averagePrice).mul(size).div(averagePrice)
    : averagePrice.sub(markPrice).mul(size).div(averagePrice);
  return pnlWithoutFee;
}

function parseSwapOrdersData(chainId, swapOrdersData, account, indexes) {
  if (!swapOrdersData || !swapOrdersData.length) {
    return [];
  }

  const extractor = (sliced) => {
    const triggerAboveThreshold = sliced[6].toString() === "1";
    const shouldUnwrap = sliced[7].toString() === "1";

    return {
      path: [sliced[0], sliced[1], sliced[2]].filter((address) => address !== AddressZero),
      amountIn: sliced[3],
      minOut: sliced[4],
      triggerRatio: sliced[5],
      triggerAboveThreshold,
      type: SWAP,
      shouldUnwrap,
    };
  };
  return _parseOrdersData(swapOrdersData, account, indexes, extractor, 5, 3).filter((order) => {
    return order.path.every((token) => isValidToken(chainId, token));
  });
}

export function getOrderKey(order) {
  return `${order.type}-${order.account}-${order.index}`;
}

export function useAccountOrders(flagOrdersEnabled, overrideAccount) {
  const { library, account: connectedAccount } = useWeb3React();
  const active = true; // this is used in Actions.js so set active to always be true
  const account = overrideAccount || connectedAccount;

  const { chainId } = useChainId();
  const shouldRequest = active && account && flagOrdersEnabled;

  const orderBookAddress = getContract(chainId, "OrderBook");
  const orderBookReaderAddress = getContract(chainId, "OrderBookReader");
  const key: any = shouldRequest ? [active, chainId, orderBookAddress, account] : false;
  const {
    data: orders = [],
    mutate: updateOrders,
    error: ordersError,
  } = useSWR(key, {
    dedupingInterval: 5000,
    fetcher: async (active, chainId, orderBookAddress, account) => {
      const provider = getProvider(library, chainId);
      const orderBookContract = new ethers.Contract(orderBookAddress, OrderBook.abi, provider);
      const orderBookReaderContract = new ethers.Contract(orderBookReaderAddress, OrderBookReader.abi, provider);

      const fetchIndexesFromServer = () => {
        //TODO
        // const ordersIndexesUrl = `${getServerBaseUrl(chainId)}/orders_indices?account=${account}`;
        // return fetch(ordersIndexesUrl)
        //   .then(async (res) => {
        //     const json = await res.json();
        //     const ret = {};
        //     for (const key of Object.keys(json)) {
        //       ret[key.toLowerCase()] = json[key].map((val) => parseInt(val.value)).sort((a, b) => a - b);
        //     }

        //     return ret;
        //   })
        //   .catch(() => ({ swap: [], increase: [], decrease: [] }));
        return { swap: [], increase: [], decrease: [] };
      };

      const fetchLastIndex = async (type) => {
        const method = type.toLowerCase() + "OrdersIndex";
        return await orderBookContract[method](account).then((res) => bigNumberify(res._hex)!.toNumber());
      };

      const fetchLastIndexes = async () => {
        const [swap, increase, decrease] = await Promise.all([
          fetchLastIndex("swap"),
          fetchLastIndex("increase"),
          fetchLastIndex("decrease"),
        ]);

        return { swap, increase, decrease };
      };

      const getRange = (to: number, from?: number) => {
        // const LIMIT = 10;
        const _indexes: number[] = [];
        // from = from || Math.max(to - LIMIT, 0);
        from = from || 0;
        for (let i = to - 1; i >= from; i--) {
          _indexes.push(i);
        }
        return _indexes;
      };

      const getIndexes = (knownIndexes, lastIndex) => {
        if (knownIndexes.length === 0) {
          return getRange(lastIndex);
        }
        return [
          ...knownIndexes,
          ...getRange(lastIndex, knownIndexes[knownIndexes.length - 1] + 1).sort((a, b) => b - a),
        ];
      };

      const getOrders = async (method, knownIndexes, lastIndex, parseFunc) => {
        const indexes = getIndexes(knownIndexes, lastIndex);
        const ordersData = await orderBookReaderContract[method](orderBookAddress, account, indexes);
        const orders = parseFunc(chainId, ordersData, account, indexes);

        return orders;
      };

      try {
        const [serverIndexes, lastIndexes]: any = await Promise.all([fetchIndexesFromServer(), fetchLastIndexes()]);
        const [swapOrders = [], increaseOrders = [], decreaseOrders = []] = await Promise.all([
          getOrders("getSwapOrders", serverIndexes.swap, lastIndexes.swap, parseSwapOrdersData),
          getOrders("getIncreaseOrders", serverIndexes.increase, lastIndexes.increase, parseIncreaseOrdersData),
          getOrders("getDecreaseOrders", serverIndexes.decrease, lastIndexes.decrease, parseDecreaseOrdersData),
        ]);
        return [...swapOrders, ...increaseOrders, ...decreaseOrders];
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }
    },
  });

  return [orders, updateOrders, ordersError];
}

export function getAccountUrl(chainId, account) {
  if (!account) {
    return getExplorerUrl(chainId);
  }
  return getExplorerUrl(chainId) + "address/" + account;
}

export function isMobileDevice(navigator) {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const CHART_PERIODS = {
  "5m": 60 * 5,
  "15m": 60 * 15,
  "1h": 60 * 60,
  "4h": 60 * 60 * 4,
  "1d": 60 * 60 * 24,
};
export const CHART_MICROSWAP_PERIODS = {
  "1H": "1H",
  "4H": "4H",
  "1D": "1D",
  "1W": "1W",
  "1M": "1M",
};

export function getTotalVolumeSum(volumes) {
  if (!volumes || volumes.length === 0) {
    return;
  }

  let volume = bigNumberify(0)!;

  for (let i = 0; i < volumes.length; i++) {
    volume = volume.add(volumes[i].data.volume);
  }

  return volume;
}

export function getBalanceAndSupplyData(balances) {
  if (!balances || balances.length === 0) {
    return {};
  }

  const keys = ["gmx", "esGmx", "glp", "stakedGmxTracker", "mmy_wftm"];
  const balanceData = {};
  const supplyData = {};
  const propsLength = 2;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    balanceData[key] = balances[i * propsLength];
    supplyData[key] = balances[i * propsLength + 1];
  }

  return { balanceData, supplyData };
}

export function getDepositBalanceData(depositBalances) {
  if (!depositBalances || depositBalances.length === 0) {
    return;
  }

  const keys = [
    "gmxInStakedGmx",
    "esGmxInStakedGmx",
    "stakedGmxInBonusGmx",
    "bonusGmxInFeeGmx",
    "bnGmxInFeeGmx",
    "glpInStakedGlp",
  ];
  const data = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = depositBalances[i];
  }

  return data;
}

export function getVestingData(vestingInfo) {
  if (!vestingInfo || vestingInfo.length === 0) {
    return;
  }

  const keys = ["gmxVester", "glpVester"];
  const data = {};
  const propsLength = 7;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = {
      pairAmount: vestingInfo[i * propsLength],
      vestedAmount: vestingInfo[i * propsLength + 1],
      escrowedBalance: vestingInfo[i * propsLength + 2],
      claimedAmounts: vestingInfo[i * propsLength + 3],
      claimable: vestingInfo[i * propsLength + 4],
      maxVestableAmount: vestingInfo[i * propsLength + 5],
      averageStakedAmount: vestingInfo[i * propsLength + 6],
    };

    data[key + "PairAmount"] = data[key].pairAmount;
    data[key + "VestedAmount"] = data[key].vestedAmount;
    data[key + "EscrowedBalance"] = data[key].escrowedBalance;
    data[key + "ClaimSum"] = data[key].claimedAmounts.add(data[key].claimable);
    data[key + "Claimable"] = data[key].claimable;
    data[key + "MaxVestableAmount"] = data[key].maxVestableAmount;
    data[key + "AverageStakedAmount"] = data[key].averageStakedAmount;
  }

  return data;
}

export function getStakingData(stakingInfo) {
  if (!stakingInfo || stakingInfo.length === 0) {
    return;
  }

  const keys = ["stakedGmxTracker", "bonusGmxTracker", "feeGmxTracker", "stakedGlpTracker", "feeGlpTracker"];
  const data = {};
  const propsLength = 5;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = {
      claimable: stakingInfo[i * propsLength],
      tokensPerInterval: stakingInfo[i * propsLength + 1],
      averageStakedAmounts: stakingInfo[i * propsLength + 2],
      cumulativeRewards: stakingInfo[i * propsLength + 3],
      totalSupply: stakingInfo[i * propsLength + 4],
    };
  }

  return data;
}

export function getProcessedData(
  balanceData,
  supplyData,
  depositBalanceData,
  stakingData,
  vestingData,
  aum,
  nativeTokenPrice = 1,
  stakedGmxSupply,
  gmxPrice,
  gmxSupply
) {
  if (
    !balanceData ||
    !supplyData ||
    !depositBalanceData ||
    !stakingData ||
    !vestingData ||
    !aum ||
    !nativeTokenPrice ||
    !stakedGmxSupply ||
    !gmxPrice ||
    !gmxSupply
  ) {
    return {};
  }

  const data: any = {};

  data.gmxBalance = balanceData.gmx;
  data.gmxBalanceUsd = balanceData.gmx.mul(gmxPrice).div(expandDecimals(1, 18));

  data.mmy_wftmBalance = balanceData.mmy_wftm;
  // data.mmy_wftmBalanceUsd = balanceData.mmy_wftm.mul(mmy_wftmPrice).div(expandDecimals(1, 18));

  data.gmxSupply = bigNumberify(gmxSupply);

  data.gmxSupplyUsd = data.gmxSupply.mul(gmxPrice).div(expandDecimals(1, 18));
  data.stakedGmxSupply = stakedGmxSupply;
  data.stakedGmxSupplyUsd = stakedGmxSupply.mul(gmxPrice).div(expandDecimals(1, 18));
  data.gmxInStakedGmx = depositBalanceData.gmxInStakedGmx;
  data.gmxInStakedGmxUsd = depositBalanceData.gmxInStakedGmx.mul(gmxPrice).div(expandDecimals(1, 18));

  data.esGmxBalance = balanceData.esGmx;
  data.esGmxBalanceUsd = balanceData.esGmx.mul(gmxPrice).div(expandDecimals(1, 18));

  data.stakedGmxTrackerSupply = supplyData.stakedGmxTracker;
  data.stakedGmxTrackerSupplyUsd = supplyData.stakedGmxTracker.mul(gmxPrice).div(expandDecimals(1, 18));
  data.stakedEsGmxSupply = data.stakedGmxTrackerSupply.sub(data.stakedGmxSupply);
  data.stakedEsGmxSupplyUsd = data.stakedEsGmxSupply.mul(gmxPrice).div(expandDecimals(1, 18));

  data.esGmxInStakedGmx = depositBalanceData.esGmxInStakedGmx;
  data.esGmxInStakedGmxUsd = depositBalanceData.esGmxInStakedGmx.mul(gmxPrice).div(expandDecimals(1, 18));

  data.bnGmxInFeeGmx = depositBalanceData.bnGmxInFeeGmx;
  data.bonusGmxInFeeGmx = depositBalanceData.bonusGmxInFeeGmx;
  data.feeGmxSupply = stakingData.feeGmxTracker.totalSupply;
  data.feeGmxSupplyUsd = data.feeGmxSupply.mul(gmxPrice).div(expandDecimals(1, 18));

  data.stakedGmxTrackerRewards = stakingData.stakedGmxTracker.claimable;
  data.stakedGmxTrackerRewardsUsd = stakingData.stakedGmxTracker.claimable.mul(gmxPrice).div(expandDecimals(1, 18));

  data.bonusGmxTrackerRewards = stakingData.bonusGmxTracker.claimable;

  data.feeGmxTrackerRewards = stakingData.feeGmxTracker.claimable;
  data.feeGmxTrackerRewardsUsd = stakingData.feeGmxTracker.claimable.mul(nativeTokenPrice).div(expandDecimals(1, 18));

  data.boostBasisPoints = bigNumberify(0);
  if (data && data.bnGmxInFeeGmx && data.bonusGmxInFeeGmx && data.bonusGmxInFeeGmx.gt(0)) {
    data.boostBasisPoints = data.bnGmxInFeeGmx.mul(BASIS_POINTS_DIVISOR).div(data.bonusGmxInFeeGmx);
  }

  data.stakedGmxTrackerAnnualRewardsUsd = stakingData.stakedGmxTracker.tokensPerInterval
    .mul(SECONDS_PER_YEAR)
    .mul(gmxPrice)
    .div(expandDecimals(1, 18));
  data.gmxAprForEsGmx =
    data.stakedGmxTrackerSupplyUsd && data.stakedGmxTrackerSupplyUsd.gt(0)
      ? data.stakedGmxTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.stakedGmxTrackerSupplyUsd)
      : bigNumberify(0);
  data.feeGmxTrackerAnnualRewardsUsd = stakingData.feeGmxTracker.tokensPerInterval
    .mul(SECONDS_PER_YEAR)
    .mul(nativeTokenPrice)
    .div(expandDecimals(1, 18));
  data.gmxAprForNativeToken =
    data.feeGmxSupplyUsd && data.feeGmxSupplyUsd.gt(0)
      ? data.feeGmxTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.feeGmxSupplyUsd)
      : bigNumberify(0);
  data.gmxBoostAprForNativeToken = data.gmxAprForNativeToken.mul(data.boostBasisPoints).div(BASIS_POINTS_DIVISOR);
  data.gmxAprTotal = data.gmxAprForNativeToken.add(data.gmxAprForEsGmx);
  data.gmxAprTotalWithBoost = data.gmxAprForNativeToken.add(data.gmxBoostAprForNativeToken).add(data.gmxAprForEsGmx);
  data.gmxAprForNativeTokenWithBoost = data.gmxAprForNativeToken.add(data.gmxBoostAprForNativeToken);

  data.totalGmxRewardsUsd = data.stakedGmxTrackerRewardsUsd.add(data.feeGmxTrackerRewardsUsd);

  data.glpSupply = supplyData.glp;
  data.glpPrice =
    data.glpSupply && data.glpSupply.gt(0)
      ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(data.glpSupply)
      : expandDecimals(1, USD_DECIMALS);

  data.glpSupplyUsd = supplyData.glp.mul(data.glpPrice).div(expandDecimals(1, 18));

  data.glpBalance = depositBalanceData.glpInStakedGlp;
  data.glpBalanceUsd = depositBalanceData.glpInStakedGlp.mul(data.glpPrice).div(expandDecimals(1, GLP_DECIMALS));

  data.stakedGlpTrackerRewards = stakingData.stakedGlpTracker.claimable;
  data.stakedGlpTrackerRewardsUsd = stakingData.stakedGlpTracker.claimable.mul(gmxPrice).div(expandDecimals(1, 18));

  data.feeGlpTrackerRewards = stakingData.feeGlpTracker.claimable;
  data.feeGlpTrackerRewardsUsd = stakingData.feeGlpTracker.claimable.mul(nativeTokenPrice).div(expandDecimals(1, 18));

  data.stakedGlpTrackerAnnualRewardsUsd = stakingData.stakedGlpTracker.tokensPerInterval
    .mul(SECONDS_PER_YEAR)
    .mul(gmxPrice)
    .div(expandDecimals(1, 18));
  data.glpAprForEsGmx =
    data.glpSupplyUsd && data.glpSupplyUsd.gt(0)
      ? data.stakedGlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.glpSupplyUsd)
      : bigNumberify(0);
  data.feeGlpTrackerAnnualRewardsUsd = stakingData.feeGlpTracker.tokensPerInterval
    .mul(SECONDS_PER_YEAR)
    .mul(nativeTokenPrice)
    .div(expandDecimals(1, 18));
  data.glpAprForNativeToken =
    data.glpSupplyUsd && data.glpSupplyUsd.gt(0)
      ? data.feeGlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.glpSupplyUsd)
      : bigNumberify(0);
  data.glpAprTotal = data.glpAprForNativeToken.add(data.glpAprForEsGmx);

  data.totalGlpRewardsUsd = data.stakedGlpTrackerRewardsUsd.add(data.feeGlpTrackerRewardsUsd);

  data.totalEsGmxRewards = data.stakedGmxTrackerRewards.add(data.stakedGlpTrackerRewards);
  data.totalEsGmxRewardsUsd = data.stakedGmxTrackerRewardsUsd.add(data.stakedGlpTrackerRewardsUsd);

  data.gmxVesterRewards = vestingData.gmxVester.claimable;
  data.glpVesterRewards = vestingData.glpVester.claimable;
  data.totalVesterRewards = data.gmxVesterRewards.add(data.glpVesterRewards);
  data.totalVesterRewardsUsd = data.totalVesterRewards.mul(gmxPrice).div(expandDecimals(1, 18));
  data.gmxVesterRewardsUsd = data.gmxVesterRewards.mul(gmxPrice).div(expandDecimals(1, 18));
  data.glpVesterRewardsUsd = data.glpVesterRewards.mul(gmxPrice).div(expandDecimals(1, 18));
  data.totalNativeTokenRewards = data.feeGmxTrackerRewards.add(data.feeGlpTrackerRewards);
  data.totalNativeTokenRewardsUsd = data.feeGmxTrackerRewardsUsd.add(data.feeGlpTrackerRewardsUsd);

  data.totalRewardsUsd = data.totalEsGmxRewardsUsd.add(data.totalNativeTokenRewardsUsd).add(data.totalVesterRewardsUsd);

  return data;
}

export function getPageTitle(data) {
  return `${data} | Decentralized
  Perpetual Exchange | MUMMY`;
}

export function isHashZero(value) {
  return value === ethers.constants.HashZero;
}
export function isAddressZero(value) {
  return value === ethers.constants.AddressZero;
}

export function isDevelopment() {
  return !window.location.host?.includes("gmx.io") && !window.location.host?.includes("ipfs.io");
}

export function isLocal() {
  return window.location.host?.includes("localhost");
}

export function getHomeUrl() {
  if (isLocal()) {
    return "http://localhost:3010";
  }

  return "https://www.mummy.finance/";
}

export function getAppBaseUrl() {
  if (isLocal()) {
    return "http://localhost:3011/#";
  }

  return "https://app.mummy.finance/";
}

export function getRootShareApiUrl() {
  if (isLocal()) {
    return "https://gmxs.vercel.app";
  }

  return "https://share.mummy.finance";
}

export function getTradePageUrl() {
  if (isLocal()) {
    return "http://localhost:3011/#/trade";
  }

  return "https://app.mummy.finance/#/trade";
}

export function importImage(name) {
  const theme = window.localStorage.getItem(`"${THEME_KEY}"`);
  const isLightTheme = theme && theme.includes(THEME_LIGHT);

  let tokenImage = "";

  if (isLightTheme) {
    try {
      tokenImage = require("img/light/" + name);
    } catch (_) {
      try {
        tokenImage = require("img/" + name);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  } else {
    try {
      tokenImage = require("img/" + name);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return tokenImage;
}

export function getTwitterIntentURL(text, url = "", hashtag = "") {
  let finalURL = "https://twitter.com/intent/tweet?text=";
  if (text.length > 0) {
    finalURL += Array.isArray(text) ? text.map((t) => encodeURIComponent(t)).join("%0a%0a") : encodeURIComponent(text);
    if (hashtag.length > 0) {
      finalURL += "&hashtags=" + encodeURIComponent(hashtag.replace(/#/g, ""));
    }
    if (url.length > 0) {
      finalURL += "&url=" + encodeURIComponent(url);
    }
  }
  return finalURL;
}

export function getPositionForOrder(account, order, positionsMap) {
  const key = getPositionKey(account, order.collateralToken, order.indexToken, order.isLong);

  const position = positionsMap[key];

  return position && position.size && position.size.gt(0) ? position : null;
}

export function getOrderError(account, order, positionsMap, position) {
  if (order.type !== DECREASE) {
    return;
  }

  const positionForOrder = position ? position : getPositionForOrder(account, order, positionsMap);

  if (!positionForOrder) {
    return `No open position, order cannot be executed unless a position is opened`;
  }
  if (positionForOrder.size.lt(order.sizeDelta)) {
    return `Order size is bigger than position, will only be executable if position increases`;
  }

  if (positionForOrder.size.gt(order.sizeDelta)) {
    if (positionForOrder.size.sub(order.sizeDelta).lt(positionForOrder.collateral.sub(order.collateralDelta))) {
      return `Order cannot be executed as it would reduce the position's leverage below 1`;
    }
    if (positionForOrder.size.sub(order.sizeDelta).lt(expandDecimals(5, USD_DECIMALS))) {
      return `Order cannot be executed as the remaining position would be smaller than $5.00`;
    }
  }
}

export function arrayURLFetcher(...urlArr) {
  const fetcher = (url) => fetch(url).then((res) => res.json());
  return Promise.all(urlArr.map(fetcher));
}

export function shouldShowRedirectModal(timestamp) {
  const thirtyDays = 1000 * 60 * 60 * 24 * 30;
  const expiryTime = timestamp + thirtyDays;
  return !isValidTimestamp(timestamp) || Date.now() > expiryTime;
}

export function getStatsInfo(data) {
  if (!data || data.length === 0) {
    return {};
  }
  let clone = JSON.parse(JSON.stringify(data)).filter((d) => d.message !== "api not found");
  return clone.reduce(
    (acc, cv, index) => {
      if (index !== ACTIVE_CHAIN_IDS.indexOf(FANTOM_LEGACY)) {
        acc.totalFees = acc.totalFees.add(cv.totalFees);
        acc.volume24H = acc.volume24H.add(cv.volume24H);
        acc.totalVolume = acc.totalVolume.add(cv.totalVolume);
        acc.longOpenInterest = acc.longOpenInterest.add(cv.longOpenInterest);
        acc.shortOpenInterest = acc.shortOpenInterest.add(cv.shortOpenInterest);
        //TODO hardcode
        //Fee OP
        if (index === 2 && Date.now() < 1737504000000) {
          cv.feeSinceToNow = bigNumberify("3608066369422276395752579959619584");
          // let fee = bigNumberify(cv.feeSinceToNow)
          // cv.feeSinceToNow = fee?.sub('13000066369422276395752579959619584')
        }
        //Fee Fantom
        if (index === 0 && Date.now() < 1737504000000) {
          cv.feeSinceToNow = bigNumberify("3625048286192823618318105684475904");
        }
        //FANTOM_LEGACY
        if (index === 1 && Date.now() < 1701820800000) {
          cv.feeSinceToNow = bigNumberify("0000286192823618318105684475904");
        }
        //ARBITRUM
        if (index === 3 && Date.now() < 1702425600000) {
          cv.feeSinceToNow = bigNumberify("2917248286192823618318105684475904");
        }
        //BASE
        if (index === 4 && Date.now() < 1701820800000) {
          cv.feeSinceToNow = bigNumberify("491248286192823618318105684475904");
        }
        acc.feeSinceToNow = acc.feeSinceToNow.add(cv.feeSinceToNow);
        acc.openInterest = acc.openInterest.add(cv.openInterest);
      }
      acc.totalUser = acc.totalUser.add(cv.totalUser);

      acc[ACTIVE_CHAIN_IDS[index]] = cv;
      return acc;
    },
    {
      totalFees: bigNumberify(0),
      volume24H: bigNumberify(0),
      totalVolume: bigNumberify(0),
      longOpenInterest: bigNumberify(0),
      shortOpenInterest: bigNumberify(0),
      feeSinceToNow: bigNumberify(0),
      openInterest: bigNumberify(0),
      totalUser: bigNumberify(0),
    }
  );
}

export function getStatsInfoV2(data) {
  if (!data) return {};

  let mapped = {};

  const filteredData = data.filter((d) => d.message === "Success").map((d) => d.data);
  let statByChains = {};
  const formattedData = filteredData.reduce((acc: any, cur: any, index: number) => {
    const { openInterest, ..._cur } = cur;

    const formattedCur = {};
    for (const [key, value] of Object.entries(_cur)) {
      if (key === "feeSince" && ACTIVE_CHAIN_IDS_V2[index] === FANTOM && Date.now() < 1737504000000) {
        formattedCur["feeSince"] = 1694.45394;
      } else formattedCur[key] = +(value as any);

      if (!mapped[key]) {
        mapped[key] = 0;
      }
      if (key === "feeSince" && ACTIVE_CHAIN_IDS_V2[index] === FANTOM && Date.now() < 1737504000000) {
        mapped["feeSince"] = 1694.45394;
      } else mapped[key] += Number(value as any);
    }

    statByChains[ACTIVE_CHAIN_IDS_V2[index]] = {
      longAmountInterest: +openInterest.longAmount,
      shortAmountInterest: +openInterest.shortAmount,
      ...formattedCur,
    };

    return {
      ...acc,
      ...statByChains,
      longAmountInterest: acc.longAmountInterest
        ? acc.longAmountInterest + Number(openInterest.longAmount)
        : Number(openInterest.longAmount),
      shortAmountInterest: acc.shortAmountInterest
        ? acc.shortAmountInterest + Number(openInterest.shortAmount)
        : Number(openInterest.shortAmount),
    };
  }, {});

  return {
    ...formattedData,
    ...mapped,
  };
}

//for provider v2
export function getStatsInfoV3(data) {
  if (!data || data.length === 0 || isEmpty(data)) {
    return {};
  }

  return data.reduce(
    (acc, cv, index) => {
      const convertedFees = cv.data?.fees24H ? Math.round(Number(cv.data.fees)).toString() : "0";
      const convertedFees24H = cv.data?.fees24H ? Math.round(Number(cv.data.fees24H)).toString() : "0";
      const convertedVolume = cv.data?.volume ? Math.round(Number(cv.data.volume)).toString() : "0";
      const convertedVolume24H = cv.data?.volume24H ? Math.round(Number(cv.data.volume24H)).toString() : "0";
      const convertedOpenInterest = cv.data?.openInterest
        ? Math.round(Number(cv.data.openInterest.amount)).toString()
        : "0";
      const convertedTrades24H = cv.data?.trades24H ? Math.round(Number(cv.data.trades24H)).toString() : "0";
      const convertedRealizedPnl24H = cv.data?.realizedPnl24H
        ? Math.round(Number(cv.data.realizedPnl24H)).toString()
        : "0";
      const convertedNlpPrice = cv.data?.nlpPrice ? Math.round(Number(cv.data.nlpPrice)).toString() : "0";
      const convertedNlpTvl = cv.data?.nlpTvl ? Math.round(Number(cv.data.nlpTvl)).toString() : "0";
      const convertedNlpStaked = cv.data?.nlpStaked ? Math.round(Number(cv.data.nlpStaked)).toString() : "0";
      const convertedTotalUsers = cv.data?.users ? Math.round(Number(cv.data.users)).toString() : "0";

      acc.fees = acc.fees.add(convertedFees);
      acc.fees24H = acc.fees24H.add(convertedFees24H);
      acc.volume24H = convertedVolume24H !== "NaN" ? acc?.volume24H?.add(convertedVolume24H) : undefined;
      acc.volume = convertedVolume !== "NaN" ? acc?.volume?.add(convertedVolume) : undefined;
      acc.realizedPnl24H = acc?.realizedPnl24H?.add(convertedRealizedPnl24H);
      // acc.longOpenInterest = acc.longOpenInterest.add(cv.longOpenInterest);
      // acc.shortOpenInterest = acc.shortOpenInterest.add(cv.shortOpenInterest);
      // acc.feeSinceToNow = acc.feeSinceToNow.add(cv.feeSinceToNow);
      acc.openInterest = convertedVolume !== "NaN" ? acc?.openInterest?.add(convertedOpenInterest) : undefined;
      acc.users = convertedTotalUsers !== "NaN" ? acc?.users?.add(convertedTotalUsers) : undefined;
      acc.trades = acc.trades.add(cv.data?.trades || bigNumberify(0));
      acc.trades24H = convertedTrades24H !== "NaN" ? acc?.trades24H?.add(convertedTrades24H) : undefined;
      acc.nlpPrice = acc.nlpPrice.add(convertedNlpPrice);
      acc.nlpTvl = acc.nlpTvl.add(convertedNlpTvl);
      acc.nlpStaked = acc.nlpStaked.add(convertedNlpStaked);

      acc[ACTIVE_CHAIN_IDS_V2[index]] = cv;
      return acc;
    },
    {
      fees: bigNumberify(0),
      fees24H: bigNumberify(0),
      volume24H: bigNumberify(0),
      volume: bigNumberify(0),
      trades: bigNumberify(0),
      trades24H: bigNumberify(0),
      realizedPnl24H: bigNumberify(0),
      // longOpenInterest: bigNumberify(0),
      // shortOpenInterest: bigNumberify(0),
      // feeSinceToNow: bigNumberify(0),
      openInterest: bigNumberify(0),
      nlpPrice: bigNumberify(0),
      nlpTvl: bigNumberify(0),
      nlpStaked: bigNumberify(0),
      users: bigNumberify(0),
    }
  );
}

export function getTokenStatsInfo(chainId, data, address) {
  if (!data || data.length === 0 || !address) {
    return {};
  }
  if (address === AddressZero) {
    address = getWrappedToken(chainId)?.address;
  }

  return {
    openInterst: data?.[0]?.[address?.toLowerCase()]?.openInterest,
    longOpenInterest: data?.[0]?.[address?.toLowerCase()]?.longOpenInterest,
    shortOpenInterest: data?.[0]?.[address?.toLowerCase()]?.shortOpenInterest,
  };
}
export function getSpread(maxPrice, minPrice, isLong) {
  if (maxPrice && minPrice && maxPrice.gt(0) && minPrice.gt(0)) {
    const fromDiff = maxPrice.sub(minPrice).div(2);
    const fromSpread = fromDiff.mul(PRECISION).div(maxPrice.add(minPrice).div(2));
    const toDiff = maxPrice.sub(minPrice).div(2);
    const toSpread = toDiff.mul(PRECISION).div(maxPrice.add(minPrice).div(2));

    let value = fromSpread.add(toSpread);

    if (isLong) {
      value = fromSpread;
    }

    return {
      value,
      isHigh: value.gt(HIGH_SPREAD_THRESHOLD),
    };
  } else {
    return {
      value: bigNumberify(0),
    };
  }
}

export function getAccruedFees(closingFee, fundingFee, borrowingFee, feeDiscount) {
  let accruedFees = bigNumberify(0);
  // if (closingFee) {
  //   accruedFees = accruedFees?.add(closingFee);
  // }
  if (fundingFee) {
    accruedFees = accruedFees?.add(fundingFee);
  }
  if (borrowingFee) {
    accruedFees = accruedFees?.add(borrowingFee);
  }
  if (feeDiscount) {
    accruedFees = accruedFees?.sub(feeDiscount);
  }
  return accruedFees;
}
export function getErrorTextStopLoss({
  isLong,
  triggerPrice,
  markPrice,
  liqPrice,
  closeQuantity,
  isShow,
}: {
  isLong: boolean;
  triggerPrice: BigNumber;
  markPrice: BigNumber;
  liqPrice: BigNumber;
  closeQuantity: BigNumber;
  isShow: boolean;
}) {
  if (!triggerPrice || !closeQuantity || closeQuantity.eq(0) || triggerPrice.eq(0)) {
    return null;
  }
  let error: null | string = null;
  if (!isShow) {
    return null;
  }

  if (!triggerPrice || triggerPrice.eq(0)) {
    return "Trigger price cannot equal 0";
  }

  if (isLong) {
    if (triggerPrice.gt(markPrice) || triggerPrice.lte(liqPrice)) {
      error = "SL price should be lower than entry price and higher than liquidation price";
    }
  } else {
    if (triggerPrice.lt(markPrice) || triggerPrice.gte(liqPrice)) {
      error = "SL price should be higher than entry price and lower than liquidation price";
    }
  }

  return error;
}

export function getErrorTextTakeProfit({
  isLong,
  triggerPrice,
  markPrice,
  liqPrice,
  closeQuantity,
  isShow,
}: {
  isLong: boolean;
  triggerPrice: BigNumber;
  markPrice: BigNumber;
  liqPrice: BigNumber;
  closeQuantity: BigNumber;
  isShow: boolean;
}) {
  if (!triggerPrice || !closeQuantity || closeQuantity.eq(0) || triggerPrice.eq(0)) {
    return null;
  }
  let error: null | string = null;
  if (!isShow) {
    return null;
  }

  if (!triggerPrice || triggerPrice.eq(0)) {
    return "Trigger price cannot equal 0";
  }

  if (isLong) {
    if (triggerPrice.lt(markPrice) || triggerPrice.lt(liqPrice)) {
      error = "TP price should be higher than entry price and liquidation price";
    }
  } else {
    if (triggerPrice.gt(markPrice) || triggerPrice.gt(liqPrice)) {
      error = "TP price should be lower than entry price and liquidation price";
    }
  }

  return error;
}

export const getLiquidPriceV2 = (size, collateral, isLong, accuredFee, averagePrice, liquidateThreshold) => {
  if (size == "0") return null;
  // console.log("####",size, collateral, isLong, accuredFee, averagePrice, liquidateThreshold)
  size = BigNumber.from(size);
  collateral = BigNumber.from(collateral);
  accuredFee = BigNumber.from(accuredFee);
  averagePrice = BigNumber.from(averagePrice);
  liquidateThreshold = BigNumber.from(liquidateThreshold);

  let collateralAfterFee = liquidateThreshold.mul(collateral).div(LIQUIDATE_THRESHOLD_DIVISOR).sub(accuredFee);
  const deltaLiqPercent = collateralAfterFee.mul(LIQUIDATE_THRESHOLD_DIVISOR).div(size);
  const offset = averagePrice.mul(deltaLiqPercent).div(LIQUIDATE_THRESHOLD_DIVISOR);
  const liquidPrice = isLong ? averagePrice.sub(offset) : averagePrice.add(offset);
  return formatAmount(liquidPrice, 30, 30, false);
};

/// data call from getUserAlivePositions(account) in ReaderMsp contract
export const convertPositionData = (data, listLiquidateThreshold, savedSlippageAmount = DEFAULT_SLIPPAGE_AMOUNT) => {
  if (!data) return null;
  const positionData: any = [];
  const listID = data[0].map((item) => formatAmount(item, 0, 0, false));
  const length = listID.length;
  for (let i = 0; i < length; i++) {
    const positionInfo = data[1][i];
    const positionConfigInfo = data[2][i];

    const paidFeeInfo = data[4][i];
    const accruedFeesInfo = data[5][i];
    const info = {
      tokenId: formatAmount(positionInfo.tokenId, 0, 0, false),
      posId: listID[i],
      size: formatAmount(positionInfo.size, 30, 30, false),
      collateral: formatAmount(positionInfo.collateral, 30, 30, false),
      refer: positionInfo.refer,
      averagePrice: formatAmount(positionInfo.averagePrice, 30, 30, false),
      fundingIndex: formatAmount(positionInfo.fundingIndex, 30, 30, false),
      isLong: positionInfo.isLong,
      paidPositionFee: formatAmount(paidFeeInfo[0], 30, 30, false),
      paidBorrowFee: formatAmount(paidFeeInfo[1], 30, 30, false),
      paidFundingFee: formatAmount(paidFeeInfo[2], 30, 30, false),
      accruedPositionFee: formatAmount(accruedFeesInfo[0], 30, 30, false),
      accruedBorrowFee: formatAmount(accruedFeesInfo[1], 30, 30, false),
      accruedFundingFee: formatAmount(accruedFeesInfo[2], 30, 30, false),
      createdAt: Number(formatAmount(positionInfo.lastIncreasedTime, 0, 0, false)) || 0,
      leverage: formatAmount(
        positionInfo.size.mul(parseValue("1", 30)).div(positionInfo.collateral) || 0,
        30,
        2,
        false
      ),
      slippage: savedSlippageAmount,
      positionType: POSITION_TYPES[positionConfigInfo.positionType],
      liquidationPrice: getLiquidPriceV2(
        positionInfo.size,
        positionInfo.collateral,
        positionInfo.isLong,
        accruedFeesInfo[1].add(accruedFeesInfo[2]),
        positionInfo.averagePrice,
        listLiquidateThreshold[i]
      ),
    };
    positionData.push(info);
  }
  positionData.reverse();
  return positionData;
};

export const getCompletePosition = (positionsOnChain, positionsApi) => {
  if (!positionsOnChain) positionsOnChain = [];
  if (!positionsApi) positionsApi = [];
  const positions: any = [];
  // if (positionsOnChain.length >= positionsApi.length) {
  if (true) {
    for (let i = 0; i < positionsOnChain.length; i++) {
      const onchainPosition = positionsOnChain[i];
      let isMerge = false;
      for (let j = 0; j < positionsApi.length; j++) {
        const apiPosition = positionsApi[j];
        if (onchainPosition.posId === apiPosition.posId) {
          isMerge = true;
          const data = {
            ...onchainPosition,
            realisedPnl: apiPosition.realisedPnl,
            pendingCollateral: apiPosition.pendingCollateral,
            positionType: apiPosition.positionType,
          };
          positions.push(data);
          break;
        }
      }
      if (!isMerge) {
        positions.push(onchainPosition);
      }
    }
  } else {
    for (let i = 0; i < positionsApi.length; i++) {
      const apiPosition = positionsApi[i];
      let isMerge = false;
      for (let j = 0; j < positionsOnChain.length; j++) {
        const onchainPosition = positionsOnChain[j];
        if (onchainPosition.posId === apiPosition.posId) {
          isMerge = true;
          const data = {
            ...onchainPosition,
            ...apiPosition,
          };
          positions.push(data);
          break;
        }
      }
      if (!isMerge) {
        positions.push(apiPosition);
      }
    }
  }
  return positions;
};
export const convertPositionFromLogsData = (events, markPrice, createdAt) => {
  let position = {};
  if (events) {
    position = {
      posId: events[0].value,
      isLong: events[2].value,
      tokenId: events[3].value,
      positionType: events[4].value,
      createdAt,
      averagePrice: markPrice,
      size: formatAmount(events[6].value[3], 30, 30, false),
      collateral: formatAmount(events[6].value[2], 30, 30, false),
      leverage:
        Number(formatAmount(events[6].value[3], 30, 30, false)) /
        Number(formatAmount(events[6].value[2], 30, 30, false)),
      accruedPositionFee: 0,
      accruedFundingFee: 0,
      accruedBorrowFee: 0,
      paidPositionFee: 0,
      paidBorrowFee: 0,
      paidFundingFee: 0,
    };
  }
  return position;
};
export function getTradePageTitle(price, pair) {
  return `${price || "--"} | ${pair || "--"} | Mummy Finance`;
}

export function isActiveV2(chainId: number) {
  return ACTIVE_CHAIN_IDS_V2.includes(chainId);
}

export function isActiveVmmyV2(chainId: number) {
  return ACTIVE_CHAIN_IDS_VMMY.includes(chainId);
}
